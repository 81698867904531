import React, { useEffect, useState } from 'react'
import PageContainer from '../../layout/PagecContainer'
import { Table } from 'react-bootstrap';
import Emptysubscripe from './Emptysubscripe';
import { useSelector } from 'react-redux';
import { getSubscripeUser } from '../../../utils/packagesubscriptions';
import Subscripe from './ModalPay';
import RenewSubscriptions from './RenewSubscriptions';
import { HomePage } from '../../../utils/Translation/HomePage';
const SubscriptionsTeachers = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const loggedUser = useSelector((state) => state.loggedUser);
    const handleMySubscriptions = async () => {
        try {
            const res = await getSubscripeUser(loggedUser?.user?._id)
            // console.log(res)
            if (res?.data?.case) {
                setSubscriptions(res?.data?.subscriptions)
            } else {
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        handleMySubscriptions()
    }, [])

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    return (
        <PageContainer>
            <div style={{ marginTop: "130px" }} className='font'>
                <div className='sharedBackground'>
                    <p>{HomePage.teacherSub[lang]}</p>
                </div>
                <div className='container'>
                    {
                        subscriptions?.length > 0 ? (
                            <Table className='font' responsive dir={dir}>
                                <thead>
                                    <tr>
                                        <th>{HomePage.serviceName[lang]}</th>
                                        <th>{HomePage.serviceDet[lang]}</th>
                                        <th>{HomePage.serviceExpire[lang]}</th>
                                        <th>{HomePage.serviceRenew[lang]}</th>
                                        <th>{HomePage.priceMonth[lang]} </th>
                                        <th> {HomePage.priceAnual[lang]}</th>
                                        <th> {HomePage.discountPriceMonth[lang]}</th>
                                        <th>{HomePage.discountPriceAnnual[lang]} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        subscriptions?.length > 0 ? (
                                            subscriptions?.map((sub, index) => {
                                                return (
                                                    <tr key={sub?._id}>
                                                        <td>{sub?.packageSubscriptionData?.name}</td>
                                                        <td>
                                                            <ul className="package-features" style={{ lineHeight: "10px" }}>
                                                                <li className="">{HomePage.pricemonthDir[lang]}:{sub?.packageSubscriptionData?.monthlyPrice} {HomePage.egp[lang]}</li>
                                                                <li className="">{HomePage.annualPrice[lang]}:{sub?.packageSubscriptionData?.annuallyPrice}{HomePage.egp[lang]}</li>
                                                                <li className="">{HomePage.maxValue[lang]}:{sub?.packageSubscriptionData?.maxValue}
                                                                    {HomePage.studetn[lang]}
                                                                </li>
                                                                <li className="">{HomePage.trialDuration[lang]}:{sub?.packageSubscriptionData?.trialDuration}
                                                                    {HomePage.today[lang]}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <button className='btn' style={{ background: "#2C6DCE", color: "white" }}>
                                                                {sub?.expires ? new Date(sub.expires).toISOString().split('T')[0] : ''}
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {/* <Subscripe sub={sub} /> */}

                                                            <RenewSubscriptions sub={sub} />

                                                        </td>
                                                        <td>
                                                            <span>{sub?.packageSubscriptionData?.monthlyPrice} {HomePage.egp[lang]}</span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {sub?.packageSubscriptionData?.discountMonthlyPrice} {HomePage.egp[lang]}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>{sub?.packageSubscriptionData?.annuallyPrice} {HomePage.egp[lang]}</span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {sub?.packageSubscriptionData?.discountAnnuallyPrice} {HomePage.egp[lang]}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (null)
                                    }

                                </tbody>
                            </Table>
                        ) : (
                            <div>
                                <Emptysubscripe />
                            </div>
                        )
                    }

                </div>


            </div>
        </PageContainer>
    )
}

export default SubscriptionsTeachers
