import { createPackageSubscriptions, deletePackageSubscription, getAllPackagesSubscriptions, updateSinglePackageSubscription } from "../utils/packagesubscriptions";

export const GET_ALL_PACKAGES_SUPSCRIPTIONS = "GET_ALL_PACKAGES_SUPSCRIPTIONS";
const getallPackagessubscriptionsdata = (packages) => {
    return {
        type: GET_ALL_PACKAGES_SUPSCRIPTIONS,
        packages
    }
}

export const Get_All_Packages_Subscriptions = () => {
    return dispatch => getAllPackagesSubscriptions().then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(getallPackagessubscriptionsdata(res?.data?.packagesSubscriptions));
        }
        return res
    })
}


// Create Package
export const CREATE_ALL_PACKAGES_SUPSCRIPTIONS = "CREATE_ALL_PACKAGES_SUPSCRIPTIONS";
const createallPackagessubscriptionsdata = (packages) => {
    return {
        type: CREATE_ALL_PACKAGES_SUPSCRIPTIONS,
        packages
    }
}

export const Create_All_Packages_Subscriptions = (data) => {
    return dispatch => createPackageSubscriptions(data).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(createallPackagessubscriptionsdata(res?.data?.newPackageSubscription));
        }
        return res
    })
}



// Delete Package
export const DELETE_PACKAGES_SUPSCRIPTIONS = "DELETE_ALL_PACKAGES_SUPSCRIPTIONS";
const deletePackagessubscriptionsdata = (packages) => {
    return {
        type: DELETE_PACKAGES_SUPSCRIPTIONS,
        packages
    }
}

export const Delete_Packages_Subscriptions = (data) => {
    return dispatch => deletePackageSubscription(data).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(deletePackagessubscriptionsdata(data));
        }
        return res
    })
}

// Update Single Package
export const UPDATE_PACKAGES_SUPSCRIPTIONS = "UPDATE_PACKAGES_SUPSCRIPTIONS";
const UpdatePackagessubscriptionsdata = (packages, id) => {
    return {
        type: UPDATE_PACKAGES_SUPSCRIPTIONS,
        packages,
        id
    }
}

export const Update_Packages_Subscriptions = (data, subId) => {
    console.log(data, subId)
    return dispatch => updateSinglePackageSubscription(data, subId).then(res => {
        console.log(res)
        if (res.data?.status === "success") {
            dispatch(UpdatePackagessubscriptionsdata(data, subId));
        }
        return res
    })
}