import { deleteMediadata, getMedia } from "../utils/media";

export const GET_ALL_MEDIA = "GET_ALL_MEDIA";
export const DELETE_MEDIA = "DELETE_MEDIA";




const getallMedia = (medias) => {
    return {
        type: GET_ALL_MEDIA,
        medias
    }
}

export const Get_All_My_Media = (userId) => {
    return dispatch => getMedia(userId).then(res => {
        // console.log(res)
        if (res.data.case) {
            dispatch(getallMedia(res.data.medias));
        }
        return res
    })
}

// For Delete Media
const deleteMedia = (id) => {
    return {
        type: DELETE_MEDIA,
        id
    }
}

export const Delete_My_Media = (id,userId) => {
    return dispatch => deleteMediadata(id,userId).then(res => {
        console.log(res)
        // if (res.data.case) {
            dispatch(deleteMedia(id));
        // }
        return res
    })
}