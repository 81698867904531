import { createSession } from "../utils/sessions";

// Create Session
export const CREATE_SESSION = "CREATE_SESSION";

const createSessionAct = (session) => {
    return {
        type: CREATE_SESSION,
        session
    }
}

export const Create_Session = (data,userId) => {
    return dispatch => createSession(data,userId).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(createSessionAct(res.data.session));
        }
        return res
    })
}
