import React, { useEffect, useState } from 'react'
import Timeline from './Timeline'
import PageContainer from '../layout/PagecContainer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import "./timeline.css"
import SharedImage from './SharedImage'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { getAllMainMaterialsFilter } from '../../utils/mainMaterial'
const Subjectselect = () => {

    const params = useParams();
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [mainMats, setMainMats] = useState([]);
    const handleBranchClick = (branchId) => {
        setSelectedBranch(branchId);
    };

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(-1)
    }

    console.log(params)

    const handleGetAllMainMats = async () => {
        try {
            const res = await getAllMainMaterialsFilter(params?.subject);
            console.log(res)
            if (res?.data?.case) {
                setMainMats(res?.data?.materials)
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        handleGetAllMainMats();
    }, [])
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className='container'>
                        <Timeline activeStep={4} />
                        <div className='row justify-content-between align-items-center' style={{ marginTop: "180px" }}>
                            <div className='col-lg-4 col-sm-12 col-md-12 '>
                                <SharedImage type={"material"} />
                            </div>
                            <div className='col-lg-8 col-sm-12 col-md-12 ' style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}

                                >
                                    {
                                        mainMats?.map((item, index) => {
                                            return (
                                                <SwiperSlide key={item?._id}>
                                                    <div className={`text-center branchDiv shadow font ${selectedBranch === item?._id ? 'active' : ''}`}
                                                        onClick={() => handleBranchClick(item?._id)} >
                                                        <p className="font">{item?.name}</p>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }


                                </Swiper>
                            </div>
                        </div>


                        <div style={{ textDecoration: "none", background: "white", textAlign: "center", padding: "10px", borderRadius: "10px", width: "50%", margin: "20px auto" }}>
                            <Link style={{ textDecoration: "none", color: "#071D5B" }} className='font'>اختيار مواد مجمعه</Link>
                        </div>
                        <div className='m-auto text-center mt-4 mb-5 font'>
                            <button onClick={handleNavigate} className='btn w-25 mx-2 prev'>السابق</button>


                            <Link to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${params?.subject}/${selectedBranch}`}

                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color: "white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >التالي</Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Subjectselect
