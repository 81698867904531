import React, { useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Update_Transaction_Act } from '../../../actions/transactionAct';
const EditTrans = ({ trans }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [category, setCategory] = useState(trans?.category);
    const [desc, setDesc] = useState(trans?.description);
    const handleClose = () => setShow(false);
    const loggedUser = useSelector((state) => state.loggedUser);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleUpdateTransaction = async()=>{
        try{
            setLoading(true)
            await dispatch(Update_Transaction_Act(trans?._id, loggedUser?.user?._id, {
                category,
                description: desc
            }))
            handleClose()
            setLoading(false)
        }catch(e){
            console.log(e)
        }
    }
    return (
        <div>
            <FaRegEdit onClick={handleShow} size={"25px"} style={{ cursor: "pointer" }} />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    Update Transaction
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <textarea className='form-control' placeholder='Description' onChange={(e) => setDesc(e?.target?.value)} value={desc} style={{ resize: "none" }} />
                        </div>
                        <div className='mt-5'>
                            <select className='form-control' onChange={(e) => setCategory(e.target.value)} value={category}>
                                <option>Select Category</option>
                                <option value={"package"}>Package</option>
                                <option value={"course"}>Course</option>
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary'>الغاء</Button>
                    <Button variant='primary' disabled={loading} onClick={handleUpdateTransaction}>
                        {loading ? "Loading..." : "تحديث"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditTrans
