import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { createPackageSubscriptions } from '../../../utils/packagesubscriptions';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Create_All_Packages_Subscriptions } from '../../../actions/packagesubscriptions';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import { useSearchParams } from 'react-router-dom';

const ModalAddServices = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [namePackage, setNamePackage] = useState(null);
    const [desc, setDesc] = useState(null);
    const [priceMonth, setPriceMonth] = useState(null);
    const [discountMonth, setDiscountMonth] = useState(null);
    const [yearPrice, setYearPrice] = useState(null);
    const [discountPrice, setDiscountPrice] = useState(null);
    const [typePackage, setTypePackage] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [trialDuration, setTrialDuration] = useState(null);
    const [image, setImage] = useState(null);
    const [cover, setCover] = useState(null);
    const [zoom, setZoom] = useState(null)
    const [trial,setTrial]=  useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser)


    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`,
            data: formData,
        })
    }

    const handleCreatePackage = async () => {
        setLoading(true); // Start loading when the request starts
        try {
            const photoRes = await uploadingFileLocally(image);
            const uploadedPhotoName = photoRes?.data?.data?.name;
            const res = await dispatch(Create_All_Packages_Subscriptions({
                name: namePackage,
                monthlyPrice: priceMonth,
                discountMonthlyPrice: discountMonth,
                annuallyPrice: yearPrice,
                discountAnnuallyPrice: discountPrice,
                packageType: typePackage,
                trial: trial,
                trialDuration: trialDuration,
                maxValue: maxValue,
                description: desc,
                coverImage: uploadedPhotoName,
                zoom: zoom,
            }));

            if (res?.data?.status === "success") {
                success(`تم اضافة الباقه`);
                handleClose();
                // Reset form fields
                setNamePackage(null);
                setDesc(null);
                setPriceMonth(null);
                setDiscountMonth(null);
                setYearPrice(null);
                setDiscountPrice(null);
                setTypePackage(null);
                setMaxValue(null);
                setTrialDuration(null);
            }
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message);
        } finally {
            setLoading(false); // Stop loading when the request is done
        }
    };

    return (
        <div>
            <Button variant="primary" onClick={handleShow} className='font'>
                اضف باقه
            </Button>

            <Modal show={show} onHide={handleClose} centered className='font' dir="rtl" style={{ textAlign: "right" }}>
                <Modal.Header closeButton>
                    <Modal.Title>اضف باقه</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>اسم الباقه</label>
                            <input type="text" name="namePackage" id="namePackage" className='form-control mt-3' value={namePackage}
                                onChange={(e) => setNamePackage(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>صورة الباقه</label>
                            <input type='file' name="photoService" id="photoService"
                                className='form-control'
                                onChange={(event) => {
                                    setImage(event.target.files[0]);
                                    const url = window.URL.createObjectURL(event.target.files[0]);
                                    setCover(url)

                                }}
                            />
                            {cover ?
                                <img width="300px" src={cover} alt="imageprogram" /> : <></>}
                        </div>
                        <div>
                            <label>وصف الباقه</label>
                            <textarea type="text" name="desc" id="desc" className='form-control mt-3' style={{ resize: "none" }}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                            ></textarea>
                        </div>
                        <div>
                            <label>سعر الباقه الشهريه</label>
                            <input type="number" name="monthPrice" id="monthPrice" className='form-control mt-3'
                                value={priceMonth}
                                onChange={(e) => setPriceMonth(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>قيمة الخصم علي الباقه الشهريه</label>
                            <input type="number" name="discoutMonth" id="discoutMonth" className='form-control mt-3'
                                value={discountMonth}
                                onChange={(e) => setDiscountMonth(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>سعر الباقه السنويه</label>
                            <input type="number" name="yearPrice" id="yearPrice" className='form-control mt-3'
                                value={yearPrice}
                                onChange={(e) => setYearPrice(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>قيمة الخصم علي الباقه السنويه</label>
                            <input type="number" name="priceYear" id="priceYear" className='form-control mt-3'
                                value={discountPrice}
                                onChange={(e) => setDiscountPrice(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>نوع الباقه</label>
                            <br />
                            <select className='form-control' onChange={(e) => setTypePackage(e.target.value)} value={typePackage}>
                                <option hidden >اختر نوع الباقه</option>
                                <option value="STORAGE">STORAGE</option>
                                <option value="STUDENT">STUDENT</option>
                            </select>
                        </div>
                        <div>
                            <label>سماح بخدمة Zoom</label>
                            <br />
                            <select className='form-control' onChange={(e) => setZoom(e.target.value)} value={zoom}>
                                <option hidden >سماح بخدمة Zoom</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                        {
                            typePackage === "STORAGE" ? (
                                <div>
                                    <label>اقصي عدد جيجات</label>
                                    <input type="number" name="maxValue" id="maxValue" className='form-control mt-3'
                                        onChange={(e) => setMaxValue(e.target.value)}
                                        value={maxValue}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <label>اقصي عدد لطلاب</label>
                                    <input type="number" name="maxValue" id="maxValue" className='form-control mt-3'
                                        onChange={(e) => setMaxValue(e.target.value)}
                                        value={maxValue}
                                    />
                                </div>
                            )
                        }

                        <div className='mt-3'>
                            <select className="form-control" onChange={(e)=>setTrial(e.target.value)}>
                                <option value={true}>سماح بالفتره التجريبيه</option>
                                <option value={false}>لا توجد فتره تجريبيه</option>
                            </select>
                        </div>

                        <div className='mt-3'>
                            <label>حدد المده التجريبيه</label>
                            <input type="number" name="trialPeriod" id="trialPeriod" className='form-control mt-3'
                                value={trialDuration}
                                onChange={(e) => setTrialDuration(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={loading}>
                        الغاء
                    </Button>
                    <Button variant="primary" onClick={handleCreatePackage} disabled={loading}>
                        {loading ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                            'اضافه'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ModalAddServices;
