import { addMainMaterialtoBranch, deleteMaterialFromBranch, getAllMainMaterials, updateMainMaterial } from "../utils/mainMaterial";


// Create Main Material (Action , Type)
export const ADD_MAIN_MATERIAL_TO_BRANCH = "ADD_MAIN_MATERIAL_TO_BRANCH";

const createMainMaterial = (material, branchId) => {
    return {
        type: ADD_MAIN_MATERIAL_TO_BRANCH,
        material,
        branchId
    }
}

export const Create_Main_Material = (data, userId, branchId) => {
    return dispatch => addMainMaterialtoBranch(data, userId).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(createMainMaterial(res.data.material, branchId));
        }
        return res
    })
}


// Get All Main Material (Action , Type)
export const GET_MAIN_MATERIAL = "GET_MAIN_MATERIAL";

const getMainMaterials = (material) => {
    return {
        type: GET_MAIN_MATERIAL,
        material
    }
}

export const Get_Main_Material = (data, userId) => {
    return dispatch => addMainMaterialtoBranch(data, userId).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(getMainMaterials(res.data.material));
        }
        return res
    })
}



// Delete Main Material From Brnach (Action , Type)
export const DELETE_MAIN_MATERIAL_FROM_BRANCH = "DELETE_MAIN_MATERIAL_FROM_BRANCH";

const deleteMainMaterials = (id,branchId) => {
    return {
        type: DELETE_MAIN_MATERIAL_FROM_BRANCH,
        id,
        branchId
    }
}

export const Delete_Main_Material = (id,branchId, userId) => {
    console.log(id)
    console.log(userId)
    return dispatch => deleteMaterialFromBranch(id, userId).then(res => {
        // console.log(res)
        // if (res.data.case) {
            dispatch(deleteMainMaterials(id,branchId));
        // }
        return res
    })
}


// Update Main Material From Brnach (Action , Type)
export const UPDATE_MAIN_MATERIAL_FROM_BRANCH = "UPDATE_MAIN_MATERIAL_FROM_BRANCH";
export const GET_MAIN_MATERIAL_FROM_LEVELID = "GET_MAIN_MATERIAL_FROM_LEVELID";
export const GET_MATERIALS_LEVEL = "GET_MATERIALS_LEVEL";

const updateMainMaterials = (id,branchId,material) => {
    return {
        type: UPDATE_MAIN_MATERIAL_FROM_BRANCH,
        id,
        branchId,
        material
    }
}

export const Update_Main_Material = (data,id,branchId, userId) => {
    console.log(id)
    console.log(userId)
    return dispatch => updateMainMaterial(data,id, userId).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(updateMainMaterials(id,branchId,res?.data?.material));
        }
        return res
    })
}


const getMainMatsFromLevel = (materials) => {
    return {
        type: GET_MAIN_MATERIAL_FROM_LEVELID,
        materials
    }
}


// Get Main Materials
export const Get_All_Main_Materials = () => {
    
    return dispatch => getAllMainMaterials().then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(getMainMatsFromLevel(res?.data?.materials));
        }
        return res
    })
}


// Get Main Materials By Level
const getMaterialsLevel = (level) => {
    return {
        type: GET_MATERIALS_LEVEL,
        level
    }
}
export const Set_Main_Materials_By_Level = (level) => {
    return dispatch => {
        dispatch(getMaterialsLevel(level));
    }
}




