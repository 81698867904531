import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getContentUrls } from '../../../utils/lessons';
import "./courseBoard.css";
import "./view.css";
import { urlBase } from '../../../utils/API';
import ReactPlayer from "react-player"
import { useParams } from 'react-router-dom';
import { getallSessionByLesson } from '../../../utils/sessions';
import ShowLiveSessions from './ShowLiveSessions';

const ViewArea = ({ Lesson }) => {

  const [content, setContent] = useState([]);

  const { lesson } = useSelector(({ view }) => {
    return { lesson: view?.lesson };
  });

  const getURLS = async () => {
    if (lesson) {
      try {
        const res = await getContentUrls(!Lesson ? lesson.content : Lesson.content);
        setContent(res?.data?.newContent);
        console.log(res);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  useEffect(() => {
    getURLS();
  }, [lesson]);

  console.log(content)

  const preventContextMenu = (e) => {
    e.preventDefault();
  };


  console.log(lesson)

  const [sessions,setSessions] = useState([]);

  const getAllSessionForCalss = async () => {
    try {
      const res = await getallSessionByLesson(lesson?._id);
      console.log(res)
      if (res?.data?.case) {
        setSessions(res?.data?.sessions)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getAllSessionForCalss()
  }, [lesson])

  return (
    <div className='view-area-cont' onContextMenu={preventContextMenu}>
      {
        lesson ?(
          <div className='d-flex justify-content-between align-items-center w-100 py-3' style={{ borderRadius: "10px", background: "#283476" }}>
          <h4 style={{ color: "white" }}>{lesson ? lesson?.name : Lesson?.name}</h4>
          {
            lesson ? (
              <div className='d-flex'>
                {/* <HomeWork lesson={Lesson}   /> */}
                <ShowLiveSessions sessions={sessions} />
                {/* <MocksStd materialCode={materialCode} /> */}
              </div>
            ) : null
          }
        </div>
        ):null
      }
      {
        content.length ? (
          <>
            {lesson?.content.map((c, i) => (
              c.type.split('/').includes('image') ? (
                <div key={i} className='w-100' onContextMenu={preventContextMenu}>
                  <div className='d-flex w-50 justify-content-between align-items-center'>
                    <div className='col-lg-8 col-sm-6 col-md-6'>
                      <h2>{lesson ? lesson?.name : ""}</h2>
                    </div>
                    <div className='w-100 col-lg-4 col-sm-6 col-md-6' onContextMenu={preventContextMenu}>
                      <img
                        src={`${urlBase}/${c.name}`}
                        alt="lesson cover"
                        type={c.type}
                        style={{ width: '150px', height: '150px' }}
                      />
                    </div>
                  </div>
                </div>
              ) : c.type.split('/').includes('video') ? (
                <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>

                  <hr />
                  <div style={{ margin: '20px auto', width: "90%" }} onContextMenu={preventContextMenu}>
                    <hr />
                    <video controls width="100%" height="auto">
                      <source src={`${urlBase}/${c?.url}`} type={`video`} />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              ) : c.type.split('/').includes('pdf') ? (
                <div key={i} style={{ marginBottom: '20px', marginTop: "50px", width: '100%' }} onContextMenu={preventContextMenu}>
                  <div key={i} style={{ marginBottom: '20px', marginTop: "80px" }} onContextMenu={preventContextMenu}>
                    <div>

                      <iframe src={`${urlBase}/${c.name}`} width="100%" height="500px" />
                    </div>
                    {/* <PdfViewer fileURL={`${urlBase}/${c.name}`}/> */}
                  </div>
                </div>
              ) : c.type === "link" ? (
                <div className='w-100 m-auto'>
                  <ReactPlayer
                    url={c?.name}
                    controls={true}
                    width="100%"
                    height="500px"
                    config={{
                      youtube: {
                        playerVars: {
                          modestbranding: 1,
                          rel: 0,
                          showinfo: 0,
                          iv_load_policy: 3,
                        }
                      },
                      dailymotion: {
                        params: {
                          controls: true,
                          autoplay: false,
                          mute: false,
                          'queue-enable': false,
                          'sharing-enable': false,
                          'ui-start-screen-info': false, // Hide video title and uploader info
                          'ui-logo': false, // Hide Dailymotion logo
                        }
                      }
                    }}
                  />
                  
                </div>
              ) : null
            ))}
          </>
        ) : (
          <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
        )
      }
    </div>
  );
};

export default ViewArea;
