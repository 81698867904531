import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ErrorMsg, success } from '../../../../components/shared/modules/Notifications';
import { addHomeWorkToSession } from '../../../../utils/sessions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
const AddHw = ({ show,
    handleClose,
    sessionId }) => {


    const loggedUser = useSelector((state) => state.loggedUser);

    const [hwTask, setHwTask] = useState("");
    const [date, setDate] = useState(new Date());

    const handleHomework = async () => {
        try {
            const formattedDate = new Date(date).toISOString();
            const res = await addHomeWorkToSession({
                homeworkTask: hwTask,
                homeworkDue: formattedDate
            }, sessionId, loggedUser?.user?._id)
            console.log(res)
            if (res?.data?.case) {
                success(res?.data?.message)
            }
        } catch (e) {
            console.log(e);
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        }
    }
    return (
        <div>
            <Modal show={show} handleClose={handleClose} onHide={handleClose} centered className="font">
                <Modal.Body>
                    <div>
                        <textarea
                            name="hw"
                            id="hwork"
                            placeholder='Enter Homework'
                            className='form-control mb-2'
                            value={hwTask}
                            onChange={(e) => setHwTask(e.target.value)}
                            style={{ whiteSpace: 'pre-wrap', resize: "none" }}
                        />
                        <DatePicker
                            selected={date}
                            onChange={(date) => setDate(date)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="form-control"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                    <button className='btn btn-primary' onClick={handleHomework}>Add</button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddHw
