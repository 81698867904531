import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMsg } from '../../shared/modules/Notifications';
import { get_My_Trans } from '../../../utils/transaction';
import { Table } from 'react-bootstrap';
import Pagination from '../../../utils/Pagination';

const TransactionMe = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);
    const [trans, setTrans] = useState([])
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(null)
    const [filters, setFilters] = useState({
        status: '',
        type: ''
    });
    const limit = 5;

    const handleMyTransactions = async (page, filter) => {
        try {
            const res = await get_My_Trans(page, limit, filter, loggedUser?.user?._id);
            if (res?.data?.case) {
                setTrans(res?.data?.transactions?.transactions)
                setCount(res?.data?.transactions?.stats?.num)
            }
            console.log(res)
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        handleMyTransactions(page, filters);
    }, [page, filters])

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
        setPage(1);
    }

    useEffect(() => {
        handleMyTransactions();
    }, [])

    const totalPages = Math.ceil(count / limit);
    return (
        <div className='w-75 m-auto'>
            <div className="filters mb-3">
                <select name="status" onChange={handleFilterChange} value={filters.status} className='form-control'>
                    <option value="">All Status</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>

                </select>
                <select name="type" onChange={handleFilterChange} value={filters.type} className='form-control'>
                    <option value="">All Types</option>
                    <option value="expense">expense</option>
                    <option value="income">income</option>
                </select>
            </div>
            <Table className='font mt-5' responsive >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>amount</th>
                        <th>currency</th>
                        <th>currency</th>
                        <th>status</th>
                        <th>type</th>
                        <th>description</th>
                        <th>date</th>
                    </tr>
                </thead>
                <tbody>
                    {trans?.map((transaction, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{transaction?.category}</td>
                            <td>{transaction?.amount}</td>
                            <td>{transaction?.currency}</td>
                            <td>{transaction?.currency}</td>
                            <td>{transaction?.status}</td>
                            <td>{transaction?.type}</td>
                            <td>{transaction?.description}</td>
                            <td>{transaction?.date}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination onPress={(pageNumber) => setPage(pageNumber)} countsPage={totalPages} />
        </div>
    )
}

export default TransactionMe
