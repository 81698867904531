import { urlBase } from "./API";
import axios from 'axios';

export const getAllPackagesSubscriptions = (data) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/package-subscriptions`,
        withCredentials: true
    })
}

// Create Packages
export const createPackageSubscriptions = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/package-subscriptions`,
        data: data,
        withCredentials: true
    })
}


// Delete Package

export const deletePackageSubscription = (data) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/package-subscriptions/${data?.id}`,
        withCredentials: true
    })
}



// Get Single Package Subscription
export const getSinglePackageSubscription = (data) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/package-subscriptions/${data?.id}`,
        withCredentials: true
    })
}



// Update Package Sub
export const updateSinglePackageSubscription = (data, subId) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/package-subscriptions/${subId}`,
        data: data,
        withCredentials: true
    })
} 



// User Subscripe package
export const userSubscripePackage = (data,idPackage,userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/packages-subscriptions/${idPackage}/subscribe?userId=${userId}`,
        data: data,
        withCredentials: true
    })
}


// Get All User Subscriptions (Teacher)

export const getSubscripeUser = (userId)=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/subscriptions/me?userId=${userId}`
    })
}