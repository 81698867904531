import React from 'react'
import './products.css'
import { useDispatch, useSelector } from 'react-redux';
import Contain from '../styled-components/contain';
import Col from '../styled-components/col';
import  Modal from '../modal/modal.js';
import { useState } from 'react';
import  Button  from '../styled-components/button';
import { useNavigate } from 'react-router-dom';
import { Current_Product, Delete_Current, Delete_Product } from '../../actions/product';
import Image from "./Image"
import { useEffect } from 'react';
// import '@fontsource/roboto/300.css';
import {AiOutlineEdit, AiOutlineDelete} from 'react-icons/ai'
// import NestedList from './test'
function Products({user}) {

    const dispatch =useDispatch()
    const {products} = useSelector(mapStateToProps)
    const [product,setProduct] = useState(null)
    const [open , setOpen] = useState(false)
    const navigate = useNavigate()

    // edit function
    const edit = (p)=>{
      navigate(`/user/${user._id}/edit/product`)     
      // if(user){

      // }
      // else{

      //   navigate('/admin-dashboard/edit/product');
      // }

      // setProduct(p)
      dispatch(Current_Product(p))
    }
    const changeCover= ()=>{
        setTimeout(()=>navigate('/admin-dashboard/cover'),1000)
        // setTimeout()
    }
    const deleteProduct =(p)=>{
      dispatch(Delete_Product(p._id))
      // setOpen(false);
      dispatch(Delete_Current())
    }
    useEffect(()=>{

    },[products])

    return (

    <>  
    {/* <NestedList/> */}
    {
      products? 
      
    <ul className='products-list'>
        <h1><span id='numproducts'>Number Of Products :</span> {products.length}</h1>
       {products.map((p,i)=>
       <li key={i} 
      //  onClick={()=>{onClick(p)}}
        >
        <Contain border={'0px'}>
          <Col width='100%' justify='flex-start'>
            <Col width='20%'>
            <img src='' alt='p' className='p-img'/>
            </Col>
            <Col width='35%' fg='0' 
                 justify='left'
                 align={'center'} >
                  <span id={'productadmin'+i}>
            Product: 
            </span>
            <span id={p.name.split(' ').join('')}>
            {p.name}
            </span>
            </Col>
            { p.type==='single'?<Col width='30%' justify={'left'} align='center'>
            {`Price: ${p.price} SR`} 
            </Col>:<><Col  fg='0' width='20%'>
              <span className='icon'>
              <AiOutlineEdit onClick={()=>edit(p)}/>
              </span>
            </Col>
            <Col fg='0' width='20%'>
              <span className='icon'>
            <AiOutlineDelete onClick={()=>deleteProduct(p)}/>
            </span>
            </Col>
            </>
            }
            
            </Col>
        </Contain>
         <hr></hr>
        </li>)}
    </ul>
      :null
    }
    <Modal open={open} 
    width='70%'
    height='70%'
    onClose={()=>{setOpen(false);
    dispatch(Delete_Current()) }}>
       {product&&<Contain border='1px' height='100%' bgColor='#fff' >
        <Col width={'50%'} justify='start' border={'1px'} >
          <ul className='modal-product-details'>
            {product.type==='single'?
            <>
            <li>{product.price}</li>
            </>:
            <>
            <li>{product.name}</li>
            <li>{product.type}</li>
            <li>{product.discription}</li>
            
            </>
          }

          </ul>
        </Col>

        <Col width={'50%'} border='1px'>
          <Image image={product}/>
        </Col><br/>
       </Contain>}
        <Contain >
        <Col justify='space-around' fg={'1'}>
      <Button name={'change cover image'} onClick={changeCover}/>
      <Button name={'Delete'} onClick={deleteProduct}/>
      <Button name={'change cover image'} onClick={changeCover}/>
        </Col>
      
        </Contain>

     </Modal>
    
   
    </>
  )
}
function mapStateToProps({products}) {
    return {products};
  }
export default Products