import React, { useEffect, useState } from 'react'
import "./timeline.css"
import PageContainer from '../layout/PagecContainer'
import Timeline from './Timeline'
import teaherDet from "../../assets/teacherRevers.png"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getSingleClass } from '../../utils/class'
const CourseDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(-1)
    }

    const [singleClass, setSingleClass] = useState([]);

    console.log(singleClass)


    const handleGetSingleClass = async () => {
        try {
            const res = await getSingleClass(params?.idteacher)
            if (res?.data?.case) {
                setSingleClass(res?.data?.classes);
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        handleGetSingleClass()
    }, [params?.idteacher])

    // Add this helper function at the top of your component
    const getDayName = (id) => {
        const days = {
            1: "الاثنين",
            2: "الثلاثاء",
            3: "الأربعاء",
            4: "الخميس",
            5: "الجمعة",
            6: "السبت",
            7: "الأحد"
        };
        return days[id];
    };
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <Timeline activeStep={5} />
                    {
                        singleClass?.map((item) => {
                            return (
                                <div className="course-page font" key={item?._id}>
                                    <div className="course-details">
                                        <h2 className="course-title">{item?.name}</h2>
                                        <p className="lessons-count">
                                            السعر:{item?.packID?.price}
                                        </p>
                                        <p className="lessons-count">
                                            السعر بعد الخصم:{item?.packID?.discountPrice}
                                        </p>
                                        <h3 className="section-title">وصف الكورس:</h3>
                                        <p className="course-description">
                                            {item?.packID?.description}
                                        </p>
                                        <div className="lesson-overview">
                                            <h3 className="section-title">محتويات الدرس</h3>
                                            <ul className="lessons-list">
                                                <li>
                                                    <i className="icon play-icon">▶️</i> مقدمة الدرس
                                                </li>
                                                <li>
                                                    <i className="icon lock-icon">🔒</i> الفصل الأول
                                                </li>
                                                <li>
                                                    <i className="icon lock-icon">🔒</i> الفصل الثاني
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="lesson-overview">
                                            <div className="schedule-section">
                                                <h3 className="section-title">مواعيد الحصص</h3>
                                                <div className="schedule-list">
                                                    {item?.schedual?.[0]?.selections &&
                                                        Object.entries(item.schedual[0].selections)
                                                            .filter(([_, value]) => value.selected)
                                                            .map(([_, value]) => (
                                                                <div key={value.id} className="schedule-item">
                                                                    <span className="day">{getDayName(value.id)}</span>
                                                                    <span className="time">{value.time}</span>
                                                                </div>
                                                            ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="teacher-info">
                                        <img
                                            src={teaherDet} // Replace with the actual image URL
                                            alt="Teacher"
                                            className="teacher-image w-75"
                                        />
                                        <div className="teacher-details">
                                            <p className="teacher-name">اسم الباقه : {item?.name}</p>
                                            <p className="teacher-description">
                                                نبذة عن المعلم: معلم أول في مادة التاريخ بمدرسة فيكتوريا كوليدج
                                            </p>
                                            <div className="rating">
                                                <div className="stars">
                                                    {Array.from({ length: 5 }, (_, index) => (
                                                        <span key={index} className="star filled">★</span>
                                                    ))}
                                                </div>
                                                <p className="student-count">(350 طالب)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='m-auto text-center mt-4 mb-5 font'>
                        <button onClick={handleNavigate} className='btn w-25 mx-2 prev'>العوده للكورسات</button>
                        <Link to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${params?.subject}/${params?.teacher}/${singleClass[0]?._id}/pay`} 
                            state={{ classData: singleClass[0] }}
                        className='btn w-25' style={{ background: "#071D5B", color: "white" }}>الدفع</Link>
                        
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default CourseDetails
