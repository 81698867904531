import React, { useState } from 'react'
import Timeline from './Timeline'
import PageContainer from '../layout/PagecContainer'
import SharedImage from './SharedImage'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const Chooselevel = () => {

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(-1)
    }

    const location = useLocation();
    const eduTypes = location.state?.eduTypes;

    const params = useParams();



    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branchData, setSekectBranchData] = useState(null);

    const handleBranchClick = (branchId, branch) => {
        setSelectedBranch(branchId);
        setSekectBranchData(branch)
    };


    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className='container'>
                        <Timeline activeStep={2} />
                        <div className='row justify-content-between align-items-center' style={{ marginTop: "180px" }}>
                            <div className='col-lg-4 col-sm-12 col-md-12 '>
                                <SharedImage type={"level"} />
                            </div>
                            <div className='col-lg-8 col-sm-12 col-md-12'>
                                <div className='row justify-content-center align-items-center font mb-5'>
                                    {
                                        eduTypes?.branchs?.map((branch, index) => {
                                            return (
                                                <div className='col-lg-3' key={branch?._id}>
                                                    <div className={`text-center levelDiv`}
                                                        onClick={() => handleBranchClick(branch._id, branch)}
                                                    >
                                                        <p>{branch?.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='m-auto text-center mt-4 mb-5 font'>
                            <button onClick={handleNavigate} className='btn w-25 mx-2 prev'>السابق</button>


                            <Link to={`/reservation-system/${params.gender}/${params?.stage}/${selectedBranch}`}
                                state={{ levelData: branchData }}
                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color: "white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >التالي</Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Chooselevel
