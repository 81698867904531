import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Alert, Container, Row } from 'react-bootstrap'; // Assuming you're using Bootstrap for the modal
import { Add_Class_Teacher, New_Class } from '../../../actions/class';
import { success, ErrorMsg } from '../../../components/shared/modules/Notifications';

function AddClass() {
    const dispatch = useDispatch();
    const { branchs, allLevels, packages } = useSelector(({ branchs, levels, packages }) => ({ branchs, allLevels: levels, packages }));
    const [levels, setLevels] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '', branchId: '', levelId: '', packID: '', weekStudents: [] });
    const [response, setResponse] = useState(null);
    const [packs, setPacks] = useState([]);

    const [pack, setPack] = useState(null)
    const [currentMaterials, setCurrentMaterials] = useState([])

    const { teachers, materials, lang, dir } = useSelector(({ users, materials, packages, language }) => {
        return {
            teachers: users.teachers,
            materials,
            ...language
        }
    })

    const loggedUser = useSelector((state) => state.loggedUser)

    //    console.log(packs)
    const handleInputChange = (e) => {
        // console.log(e.target.name)
        if (!e.target.value === null) return;
        if (e.target.name === 'packID') {

            const [pa] = packs.filter(p => p._id === e.target.value);
            setPack(pa);
            console.log(pa)
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const branchIDS = branchs.filter(b => b._id === formData.branchId)[0]?.levelsIDS
        const currentLevels = branchIDS ? allLevels.filter(l => branchIDS.includes(l._id)) : [];
        setLevels(currentLevels);

        let currentPacks
        if (loggedUser?.user?.role === 'teacher') {

            currentPacks = packages?.filter(p => p.levelId === formData.levelId && p?.teacher?._id === loggedUser?.user?._id);
            // console.log('teacher',packs)
        }

        else {
            currentPacks = packages.filter(p => p.levelId === formData.levelId);
        }
        setPacks(currentPacks)
    }, [formData.levelId, formData.branchId])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const newClass = formData;
            newClass.weekStudents = pack.materials.map(m => {
                return { materialCode: m.materialCode, student: null }
            })
            const res = await dispatch(New_Class(newClass, loggedUser?.user?._id));
            setResponse(res.data);
            // console.log(res)
            if (res?.data?.case === true && loggedUser?.user?.role === "teacher") {
                const [program] = packages.filter(p => p._id === res?.data?.class.packID);
                const materialsObjs = program?.materials;
                const mats = []
                materialsObjs.forEach(m => {
                    const mt = materials.find(mm => mm.code === m.materialCode);
                    if (mt) mats.push(mt);
                })
                setCurrentMaterials(mats);

                for (const element of mats) {
                    const response = await dispatch(Add_Class_Teacher({ teacher: loggedUser?.user?._id, id: res?.data?.class?._id, materialCode: element?.code }, loggedUser?.user?._id));
                }
            }
            if (res.data.case) {

                setShowModal(false)
                success(res.data.message)
            }
            else {
                ErrorMsg(res.data.message)
            }
        } catch (error) {
            console.error('Error:', error);
            setResponse({ case: false, message: error.message });
            ErrorMsg(error.message)

        }
        // setShowModal(false);
    };
    console.log(dir)
    return (
        <Container>
            <Row>
                <button className='btn buttonsGroub mb-5' onClick={() => setShowModal(true)}>
                    <span className='font'>اضافة فصل</span>
                </button>
                <Modal show={showModal} onHide={() => setShowModal(false)} className="font" centered dir={dir}>
                    <Modal.Header>
                        <Modal.Title>اضافة فصل جديد</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formClassName">
                                <Form.Label>الاسم</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter class name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formLevel">
                                <Form.Label>اختر الفئة </Form.Label>
                                <Form.Control
                                    as="select"
                                    name="branchId"
                                    value={formData.levelId}
                                    onChange={handleInputChange}
                                >
                                    <option value="">اختر الفئة التعليمية</option>
                                    {branchs.map((branch) => (
                                        <option key={branch._id} value={branch._id}>{branch.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formLevel">
                                <Form.Label>اختر المستوى </Form.Label>
                                <Form.Control
                                    as="select"
                                    name="levelId"
                                    value={formData.levelId}
                                    onChange={handleInputChange}
                                >
                                    <option value="">اختر المستوى</option>
                                    {levels.map((level) => (
                                        <option key={level._id} value={level._id}>{level.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formLevel">
                                <Form.Label>اختر البرنامج </Form.Label>
                                {formData?.levelId && loggedUser?.user?.role === 'teacher' && !packs.length ? <Alert variant='danger'>لا توجد كورسات لك فى هذا المستوى</Alert> :
                                    <Alert variant='success'>{`يوجد ${packs.length} كورس`}</Alert>
                                }
                                {formData?.levelId && loggedUser?.user?.role !== 'teacher' && !packs.length ? <Alert variant='danger'>لا توجد كورسات فى هذا المستوى</Alert> : <></>}

                                <Form.Control
                                    as="select"
                                    name="packID"
                                    // value={formData.packID}
                                    onChange={handleInputChange}
                                >
                                    <option name='choose' value={null}>اختر البرنامج</option>
                                    {formData.levelId && packs.map((p) =>
                                        <option key={p._id} value={p._id}>{p.name}</option>
                                    )}

                                </Form.Control>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                حفظ
                            </Button>
                        </Form>
                        {response && (
                            <Alert variant={response.case ? 'success' : 'danger'}>
                                {response.message}
                            </Alert>
                        )}
                    </Modal.Body>
                </Modal>
            </Row>
        </Container>
    );
}

export default AddClass;
