import React, { useEffect, useState } from 'react'
import AddZoomUser from './AddZoomUser'
import { Table } from 'react-bootstrap'
import { ErrorMsg } from '../../../components/shared/modules/Notifications'
import { useDispatch, useSelector } from 'react-redux'
import { Get_Zoom_Users } from '../../../actions/zoomAction'
import AssignedUser from './AssignedUser'
import DeleteZoomUser from './DeleteZoomUser'

const Zoom = () => {
    const dispatch = useDispatch();


    // const handleGetZoom = async () => {
    //     try {
    //         const res = await dispatch(Get_Zoom_Users());
    //     } catch (e) {
    //         console.log(e)
    //         if (e?.response?.data) {
    //             ErrorMsg(e?.response?.data?.message)
    //         }
    //     } finally {

    //     }
    // }

    // useEffect(() => {
    //     handleGetZoom()
    // }, [])


    const zoomUsers = useSelector((state) => state.zoomReducer);
    const [zoomUserId,setZoomUserId] = useState(null);
    const [assignUserModal,setAssignUserModal] = useState(false);

    const handleAssignUser = (zoomUserIdData)=>{
        setZoomUserId(zoomUserIdData);
        setAssignUserModal(true)
    }


    return (
        <div>
            <AddZoomUser />
            <div className='mt-5 font'  >
                <Table responsive>
                    <thead>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Display Name</th>
                        <th>email</th>
                        <th>Status</th>
                        <th>Assigned Users</th>
                        <th>حذف</th>
                    </thead>
                    <tbody>
                        {
                            zoomUsers?.map((zoomUser) => {
                                return (
                                    <tr key={zoomUser?.id}>
                                        <td>{zoomUser?.first_name}</td>
                                        <td>{zoomUser?.last_name}</td>
                                        <td>{zoomUser?.display_name}</td>
                                        <td>{zoomUser?.email}</td>
                                        <td>{zoomUser?.status || "يرجي التفعيل "}</td>
                                        <td>
                                            <button className='btn btn-primary' onClick={()=>{handleAssignUser(zoomUser?.id)}}>Assign User</button>
                                        </td>
                                        <td>
                                            <DeleteZoomUser zoomUserId={zoomUser?.id} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <AssignedUser show={assignUserModal} handleClose={()=>setAssignUserModal(false)} id={zoomUserId} />
        </div>
    )
}

export default Zoom
