import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Create_Eduction_Type } from '../../../actions/eduAction';
import { toast } from 'react-toastify';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';

const Addeductiontype = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("");
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);


    const handleAddEductionType = async () => {
        try {
            setLoading(true);
            const res = await dispatch(Create_Eduction_Type({
                name: name
            }, loggedUser?.user?._id))
            console.log(res)
            if (res?.status === 201) {
                setName("");
                handleClose();
                success(res?.data?.message);
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <button className='btn btn-primary' onClick={handleShow}>Add Eduction Type</button>
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>Add Eduction Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input type='text' name='nameEduction' id="eductionType" onChange={(e) => setName(e.target.value)} placeholder='Enter Eduction Type' value={name} className='form-control' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={handleClose}>Cancel</Button>
                    <Button className="btn-variant" disabled={loading} onClick={handleAddEductionType}>
                        {loading ? "loading..." : "Add"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Addeductiontype
