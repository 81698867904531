import React from 'react'
import { useNavigate } from 'react-router-dom'
import circleImage from "../../assets/CIRCELS.png"
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
const ServiceType = () => {

    const navigate = useNavigate();
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const storageClick = async () => {
        navigate(`/service-type/${'STORAGE'}`)
    }

    const packageClick = async () => {
        navigate(`/service-type/${'STUDENT'}`)
    }


    return (
        <div className='font'>
            <div className='text-center'>
                <h2>{HomePage.teacherService[lang]}</h2>
                <p className='infoService'>{HomePage.infoServiceType[lang]}</p>
            </div>
            <div className='container mt-5 mb-5'>
                <div className='row justify-content-center align-items-center'>
                    <div className=' col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className="TypeOne" onClick={storageClick} style={{ cursor: "pointer" }}>
                            <p> {HomePage.storagesPackage[lang]}</p>
                        </div>
                    </div>
                    <div className=' col-lg-6 col-md-12 col-sm-12 col-12' onClick={packageClick} style={{ cursor: "pointer" }}>
                        <div className='Typetwo'>
                            <p>{HomePage.packagesStd[lang]}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceType
