import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Create_Zoom_User } from '../../../actions/zoomAction';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';
import { IoIosAddCircle } from "react-icons/io";

const AddZoomUser = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const dispatch = useDispatch();

    // State Data
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [pass, setPass] = useState(null);
    const [loading, setLoading] = useState(false)


    const handleCreateZoom = async () => {
        setLoading(true);
        try {
            const res = await dispatch(Create_Zoom_User({
                action: "create",
                user_info: {
                    email: email,
                    first_name: firstName,
                    last_name: lastName,
                    display_name: displayName,
                    password: pass,
                    type: 1
                }
            }));

            if (res?.data?.case) {
                success(res?.data?.message);
                handleClose();
                setFirstName(null)
                setLastName(null)
                setEmail(null)
                setDisplayName(null)
                setPass(null)
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <button className='btn btn-primary' onClick={handleShow}>Add Zoom User</button>
            
            <Modal centered show={show} onHide={handleClose} className='font' dir={dir}>
                <Modal.Header>
                    <Modal.Title>Add Zoom User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>الاسم الاول</label>
                            <input type='text' name="nameFirstZoomUser" id="nameFirstZoomUser" className='form-control'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>الاسم الثاني</label>
                            <input type='text' name="nameLastZoomUser" id="nameLastZoomUser" className='form-control'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>الايميل</label>
                            <input type='email' name="emailZoomUSer" id="emailZoomUSer" className='form-control'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div>
                            <label>اسم العرض</label>
                            <input type='text' name="displayName" id="displayName" className='form-control'
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)} />

                        </div>
                        <div>
                            <label>كلمة السر</label>
                            <input type='password' name="pass" id="pass" className='form-control'
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                            />
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='primary' onClick={handleCreateZoom} disabled={loading}>
                        {loading ? "تحميل" : "Add"}
                    </Button>
                    <Button variant='danger' onClick={handleClose}> Cancel </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddZoomUser
