import React, { useEffect, useState } from 'react'
import PageContainer from '../../layout/PagecContainer'
import { IoMdAdd } from "react-icons/io";
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Inetialize_Levels } from '../../../actions/curriculums/levels';
import { teacherCompleteRegister, teacherRequestMainMaterial } from '../../../utils/API';
import { useNavigate } from 'react-router-dom';
import { ErrorMsg, success } from '../../shared/modules/Notifications';
import { Get_All_Main_Materials, Set_Main_Materials_By_Level } from '../../../actions/mainMaterial';
const SelectSubject = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Inetialize_Levels());
    }, [])
    const { branchs, levels, toast, user } = useSelector(({ branchs, levels, notify, loggedUser }) => {
        return { branchs, levels, toast: notify.toast, user: loggedUser?.user }
    })
    const [brLevels, setBrLevels] = useState([]);
    // Add this state for selected materials
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [branchId, setBranchId] = useState(null);
    const [materialWord, setMaterialWord] = useState("");
    const [levelMaterials, setLevelMaterials] = useState([]);
    const [selectedLev, setSetSelectedLev] = useState([]);
    const [levelId, setLevelId] = useState(null);
    const loggedUser = useSelector((state) => state.loggedUser)


    const onChangeBranch = (branchID) => {
        try {
            setBranchId(branchID)
            const branch = branchs.find(b => b._id === branchID)
            const levelsIDS = branch.levelsIDS ? branch.levelsIDS : []
            const branchLevels = levels.filter(l => levelsIDS.includes(l._id));
            setBrLevels(branchLevels);
            setLevelMaterials([]) // reset level materials
            setSetSelectedLev([]);
            setLevelId(null)
        }
        catch (err) {
            console.log(err.message)
        }
    }

    const onChangeLevel = async (levelID) => {
        try {
            setLevelId(levelID)
        }
        catch (err) {
            console.log(err.message)
        }
    }

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)


    const handleCompleteRegister = async () => {
        setLoading(true)
        try {
            const res = await teacherCompleteRegister({
                materials: selectedMaterials
            }, loggedUser?.user?._id)
            console.log(res)
            if (res?.data?.case) {
                success(res?.data?.case)
                // navigate(`/dashboard/${loggedUser?.user?.username}/${loggedUser?.user?._id}//profile`)
                navigate(`/dashboard/${loggedUser?.user?.username}/${loggedUser?.user?._id}//profile`, { state: { key: true } })

            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(true)
        }
    }


    const handleMainMats = () => {
        try {
            dispatch(Set_Main_Materials_By_Level(levelId))

        } catch (e) {
            console.log(e)
        }
    }

    const mainMats = useSelector((state) => state.mainMaterialRed);


    useEffect(() => {
        if (levelId) {
            handleMainMats();
        }
    }, [levelId])

    useEffect(() => {
        dispatch(Get_All_Main_Materials())
    }, [])


    // Modify the onChange handler
    const handleMaterialSelect = (e) => {
        const selectedMaterial = mainMats.find(mat => mat.name === e.target.value);
        if (selectedMaterial && !selectedMaterials.find(m => m === selectedMaterial._id)) {
            setSelectedMaterials([...selectedMaterials, selectedMaterial._id]);
        }
    };
    // Add remove handler
    const removeMaterial = (materialId) => {
        setSelectedMaterials(selectedMaterials.filter(id => id !== materialId));
    };

    console.log(selectedMaterials)





    return (
        <div>
            <PageContainer>
                <div className='text-center font w-50' style={{ margin: "120px auto", background: "white", padding: "15px 0", borderRadius: "15px" }}>
                    <div style={{ background: "#2C6DCE", color: "white", padding: "5px" }}>
                        <p>ادخل مراحل الصف الدراسي</p>
                        <p>قم بادخال المرحلة التعليمية والمواد التي تدرسها لكل صف دراسي</p>
                    </div>
                    <div style={{ textAlign: "right" }} className='mt-3 px-2'>
                        <div>
                            <Form.Group controlId="levelSelect">
                                <Form.Label> الفئة</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => onChangeBranch(e.target.value)}
                                >
                                    <option value="">اختر الفئة</option>
                                    {branchs

                                        .map((br) => (
                                            <option key={br._id} value={br._id}>
                                                {br.name}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                            <div>
                                <Form.Group controlId="levelSelect">
                                    <Form.Label> المستوى</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => onChangeLevel(e.target.value)}
                                    >
                                        <option value="">اختر المستوى</option>
                                        {brLevels.map((level) => (
                                            <option key={level._id} value={level._id}>
                                                {level.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {levelId && (
                                    <Form.Group controlId="mainMaterialSelect">
                                        <Form.Label>المواد الرئيسية</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={handleMaterialSelect}
                                            value=""
                                        >
                                            <option value="">اختر المادة</option>
                                            {mainMats && mainMats.map((mat) => (
                                                <option key={mat._id} value={mat.name}>
                                                    {mat.name}
                                                </option>
                                            ))}
                                        </Form.Control>

                                        <div className="selected-materials mt-2">
                                            {selectedMaterials.map((materialId) => {
                                                const material = mainMats.find(m => m._id === materialId);
                                                return (
                                                    <div
                                                        key={materialId}
                                                        className="d-inline-block m-1 p-2"
                                                        style={{
                                                            background: '#e9ecef',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {material?.name}
                                                        <span
                                                            onClick={() => removeMaterial(materialId)}
                                                            className="ms-2 text-danger"
                                                            style={{ fontWeight: 'bold' }}
                                                        >
                                                            ×
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Form.Group>
                                )}
                            </div>


                            {/* <IoMdAdd style={{ background: "#2C6DCE", color: "white", borderRadius: "10px", cursor: "pointer" }} size={"25px"} /> */}

                        </div>
                        <div className='text-center'>
                            <button className='btn' style={{ background: "#2C6DCE", color: "white" }}
                                onClick={handleCompleteRegister}
                                disabled={loading}
                            >
                                {loading ? "جاري التحميل" : "تم"}
                            </button>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default SelectSubject
