import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { assignUserToZoom } from '../../../utils/zoom';
import { success } from '../../../components/shared/modules/Notifications';
import { IoIosAddCircle } from "react-icons/io";
const AssignUserToZoom = ({ teacher }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false)

    const zoomUsers = useSelector((state) => state.zoomReducer);
    const [zoomId, setZoomId] = useState(null);

    const loggedUser = useSelector((state) => state.loggedUser);


    const handleAssignUserToZoom = async () => {
        setLoading(true)
        try {
            const res = await assignUserToZoom(teacher?._id, zoomId, loggedUser?.user?._id);
            console.log(res)
            if (res?.data?.case) {
                success("User Assigned Successfully");
                handleClose();
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    // console.log(teacher)
    return (
        <div>
            {/* <button className='btn btn-primary' onClick={handleShow}>Add To  Zoom </button> */}
            <IoIosAddCircle size={"25"} className='btn-primary' onClick={handleShow}/>
            <Modal className='font' centered show={show} onHide={handleClose}>
                <Modal.Body>
                    <select className='form-control' value={zoomId} onChange={(e) => setZoomId(e.target.value)}>
                        <option hidden>Choose Zoom</option>
                        {
                            zoomUsers?.map((zoom) => {
                                return (
                                    <option value={zoom.id}>{zoom.display_name}</option>
                                )
                            })
                        }
                    </select>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='primary' disabled={loading} onClick={handleAssignUserToZoom}>
                        {loading ? "Loading..." : "Add"}
                    </Button>
                    <Button variant='danger'>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AssignUserToZoom
