import React, { useEffect, useState } from "react";
import BookingModal from "../modals/BookingModal";
import { GetMaterialsByIds } from "../../utils/materials";
import imgCard from "../../assets/Image.png";
import { Link } from "react-router-dom";
import "../../styles/programs.css";
import { urlBase } from "../../utils/API";
import GetLevelName from "../shared/GetLevelName";
import { getBranchByID } from "../../utils/branchs";
import SubscribeToTeacher from "../modals/SubscribeToTeacher";
import { useSelector } from "react-redux";
import { HomePage } from "../../utils/Translation/HomePage";

const DetailsProgram = ({ program }) => {
    const [loading, setLoading] = useState(true); // Start with loading as true
    const [materials, setMaterials] = useState([]);
    const [branch, setBranch] = useState("");

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    // console.log(program);

    const handleMaterials = async () => {
        if (program?.materials) {
            try {
                const materialCodes = program.materials.map(
                    (material) => material.materialCode
                );
                const materialsData = await GetMaterialsByIds({
                    codes: materialCodes,
                });
                if (materialsData?.data?.case) {
                    setMaterials(materialsData.data.materials);
                }
            } catch (error) {
                console.error("Error fetching materials:", error);
            } finally {
                setLoading(false); // Only set loading false if this is the last operation
            }
        } else {
            setLoading(false); // No materials to fetch, set loading to false
        }
    };

    const getBranch = async () => {
        try {
            const res = await getBranchByID({
                _id: program?.branchId,
            });

            if (res?.data?.case) {
                setBranch(res?.data?.branch?.name);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false); // Only set loading false if this is the last operation
        }
    };

    useEffect(() => {
        if (program) {
            handleMaterials();
            getBranch();
        }
    }, [program]);

    const loggedUser = useSelector((state) => state.loggedUser);

    const initialIsSubscribed = program?.teacher?.subscribers?.some(
        (subscriber) => subscriber?.user === loggedUser?.user?._id
    );

    return (
        <div className="container" dir={`${dir}`} style={{ marginTop: "150px" }}>
            {loading ? (
                <div className="text-center my-5">
                    <div className="loaderIcon">
                        <span className="loader"></span>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="sharedBackground">
                        <span>{HomePage.programDetails[lang]}</span>
                    </div>

                    <div className="px-5">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div>
                                    <p className="fs-4">
                                        {HomePage.material[lang]}:
                                        <span className="fs-6">
                                            {program?.name}
                                        </span>
                                    </p>
                                </div>

                                <div>
                                    <p className="fs-4">
                                        {HomePage.desc[lang]}:
                                        <span className="fs-6">
                                            {program?.description}
                                        </span>
                                    </p>
                                </div>
                                <p className="mb-0 cardTitle">
                                    <GetLevelName levelId={program?.levelId} />
                                </p>
                                <div>
                                    <p>{branch}</p>
                                </div>
                                <div className="row justify-content-start align-items-center mb-5">
                                    {materials.map((material, index) => (
                                        <div
                                            className="col-8 mt-5"
                                            key={material._id}
                                        >
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <p>
                                                    <span className="fs-5">
                                                        {HomePage.material_name[lang]}
                                                    </span>
                                                    :
                                                    <span>
                                                        {material.name}
                                                    </span>
                                                </p>
                                                <p>

                                                    <span className="fs-5">
                                                        {HomePage.teacher_name[lang]}
                                                    </span>
                                                    :
                                                    <span>
                                                        {
                                                            material?.teacherId
                                                                ?.name
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className="col-lg-6 col-md-6 col-sm-6"
                                style={{ textAlign: "center" }}
                            >
                                <div className="prog-img">
                                    {program?.coverImage ? (
                                        <img
                                            src={
                                                program?.coverImage
                                                    ? `${urlBase}/${program?.coverImage}`
                                                    : ""
                                            }
                                            alt=""
                                            style={{
                                                width: "250px",
                                                height: "250px",
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={Image}
                                            alt="imageData"
                                        />
                                    )}
                                </div>

                                {program?.price === 0 ? (
                                    <span className="mt-2">
                                        {HomePage.price[lang]}: <span>مجانا</span>
                                    </span>
                                ) : program?.discountPrice !== undefined ? (
                                    program?.price === program?.discountPrice ? (
                                        <span className="mt-2">
                                            {HomePage.price[lang]}:{" "}
                                            <span>{program?.price} EGP</span>
                                        </span>
                                    ) : program?.price >
                                        program?.discountPrice ? (
                                        <span className="mt-2">
                                            {HomePage.price[lang]}:
                                            <span
                                                style={{
                                                    textDecoration:
                                                        "line-through",
                                                    color: "red",
                                                }}
                                            >
                                                {" "}
                                                {program?.price} جنيه
                                            </span>
                                            <span className="mx-3">
                                                {program?.discountPrice} جنيه{" "}
                                            </span>
                                        </span>
                                    ) : null
                                ) : (
                                    <span className="mt-2">
                                        {HomePage.price[lang]}:{" "}
                                        <span>{program?.price} EGP</span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="forSubscribers mb-4 mt-5 text-center py-5 w-100">
                        {/* {initialIsSubscribed ? (
                            <div>
                                <BookingModal
                                    item={program}
                                    title={`${HomePage.doneSubscribe[lang]}`}
                                />
                            </div>
                        ) : (
                            <div className="mx-2">
                                <SubscribeToTeacher item={program} />
                            </div>
                        )} */}
                        <div>
                            <BookingModal
                                item={program}
                                title={`${HomePage.doneSubscribe[lang]}`}
                            />
                        </div>
                        <div>
                            <Link
                                className="book-button buttonOpacity text-decoration-none"
                                to="/"
                            >
                                {HomePage.return_main_page[lang]}
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsProgram;
