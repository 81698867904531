import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Delete_My_Media, Get_All_My_Media } from '../../../actions/mediaAction';
import "./media.css"
import { urlBase } from '../../../utils/API';
import { MdDelete } from "react-icons/md";
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const Media = ({ onMediaSelect, showForMedia }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleCheckboxChange = (fileId) => {
        setSelectedFiles(prev => {
            if (prev.includes(fileId)) {
                return prev.filter(id => id !== fileId);
            } else {
                return [...prev, fileId];
            }
        });
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [showMediaModal, setShowMediaModal] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);
    const media = useSelector((state) => state.mediaRed);

    const handleGetMedia = async () => {
        try {
            const res = await dispatch(Get_All_My_Media(loggedUser?.user?._id))
        } catch (e) {
            console.log(e)
        }
    }

    const handleDeleteClick = (fileId) => {
        setSelectedFileId(fileId);
        setShowDeleteModal(true);
    };

    const handleMediaClick = (file) => {
        setSelectedMedia(file);
        setShowMediaModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            setLoading(true)
            const res = await dispatch(Delete_My_Media(selectedFileId, loggedUser?.user?._id))
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
        setShowDeleteModal(false);
    };

    useEffect(() => {
        handleGetMedia();
    }, [])


    const [presignedUrls, setPresignedUrls] = useState({});

    const getPresignedUrl = async (file) => {
        if (file.service === 'aws') {
            try {
                const response = await axios.post(`${urlBase}/api/retrieve-presigned-url`, {
                    fileUrl: file.url,
                    expires: 3600
                });
                setPresignedUrls(prev => ({
                    ...prev,
                    [file._id]: response.data.url
                }));
            } catch (error) {
                console.error('Failed to get presigned URL:', error);
            }
        }
    };

    useEffect(() => {
        media.forEach(file => {
            if (file.service === 'aws') {
                getPresignedUrl(file);
            }
        });
    }, [media]);

    return (
        <div className="container">
            <div className='row justify-content-between align-items-center'>
                {media.map((file) => (
                    <div key={file._id} className=" col-lg-3 col-md-6 col-sm-12 col-12 font">
                        <div className='media-item' style={{ width: "95%" }}>
                            <div className="text-center d-flex justify-content-between align-items-center">
                                <div>
                                    <MdDelete
                                        size={"25px"}
                                        color="red"
                                        onClick={() => handleDeleteClick(file._id)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        className="media-checkbox"
                                        checked={selectedFiles.includes(file._id)}
                                        onChange={() => handleCheckboxChange(file._id)}
                                    />
                                </div>
                            </div>
                            <div className="media-image-container" onClick={() => handleMediaClick(file)}>
                                {file.mediaType?.includes('image') ? (
                                    <img
                                        src={file.service === 'aws' ? presignedUrls[file._id] : `${urlBase}/${file.url}`}
                                        alt={file.name}
                                    />
                                ) : file.mediaType?.includes('pdf') ? (
                                    <iframe
                                        src={file.service === 'aws' ? presignedUrls[file._id] : `${urlBase}/${file.url}`}
                                        width="100%"
                                        height="100%"
                                        title={file.name}
                                        frameBorder="0"
                                    />
                                ) : (
                                    <img
                                        src={file.service === 'aws' ? presignedUrls[file._id] : `${urlBase}/${file.url}`}
                                        alt={file.name}
                                    />
                                )}
                            </div>
                            <div className="media-details">
                                <p className="media-name">{file.preview}:الاسم</p>
                                <p className="media-date">التاريخ:{new Date(file.createdAt).toLocaleDateString()}</p>
                                <p className="media-date">نوع الخدمه:{file.service}</p>
                                <p className="media-date">حجم الملف:{file.size}</p>
                                {
                                    file?.service === "aws" ? (
                                        <a href={`${presignedUrls[file._id]}`}>File</a>
                                    ):(
                                        <a href={`${urlBase}/${file.url}`}>File</a>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {
                showForMedia ? (<div className="text-end mt-3">
                    <Button
                        variant="primary"
                        onClick={() => onMediaSelect(selectedFiles)}
                    >
                        Select Media
                    </Button>
                </div>) : null
            }


            <Modal
                show={showMediaModal}
                onHide={() => setShowMediaModal(false)}
                centered
                size="lg"
                className='font'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{selectedMedia?.preview}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {selectedMedia?.mediaType?.includes('image') ? (
                        <img
                            src={selectedMedia?.service === 'aws' ? presignedUrls[selectedMedia?._id] : `${urlBase}/${selectedMedia?.url}`}
                            alt={selectedMedia?.name}
                            className='w-100'
                            loading="lazy"
                        />
                    ) : selectedMedia?.mediaType?.includes('pdf') ? (
                        <iframe
                            src={selectedMedia?.service === 'aws' ? presignedUrls[selectedMedia?._id] : `${urlBase}/${selectedMedia?.url}`}
                            width="100%"
                            height="600px"
                            title={selectedMedia?.name}
                            frameBorder="0"
                        />
                    ) : (
                        <img
                            src={selectedMedia?.service === 'aws' ? presignedUrls[selectedMedia?._id] : `${urlBase}/${selectedMedia?.url}`}
                            alt={selectedMedia?.name}
                            loading="lazy"
                        />
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تأكيد الحذف</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    هل أنت متأكد من حذف هذا الملف؟
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        إلغاء
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete} disabled={loading}>
                        {loading ? "loading" : "حذف"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Media
