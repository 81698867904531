import { deleteType, getAllEduType, newEduction, updateType, updateTypeForBranch } from "../utils/eduction";

export const CREATE_EDUCTION_TYPE = 'CREATE_EDUCTION_TYPE';
const eductionType = (eduType)=>{
    return {
        type:CREATE_EDUCTION_TYPE,
        eduType
    }
}
export const Create_Eduction_Type = (data,userId)=>{
    return dispatch=>newEduction(data,userId).then(res=>{

        if(res.status === 201){
            dispatch(eductionType(res.data.eduType))
        }
        return res
    })
}

// Get All Edu Type
export const GET_EDUCTION_TYPE = 'GET_EDUCTION_TYPE';

const geteductionType = (eduTypes)=>{
    return {
        type:GET_EDUCTION_TYPE,
        eduTypes
    }
}
export const Get_Eduction_Type = ()=>{
    return dispatch=>getAllEduType().then(res=>{

        if(res.status === 200){
            dispatch(geteductionType(res.data.eduTypes))
        }
        return res
    })
}



// Delete Edu Type
export const DELETE_EDU_TYPE = 'DELETE_EDU_TYPE';

const deleteedutype = (id)=>{
    return {
        type:DELETE_EDU_TYPE,
        id
    }
}
export const Delete_Edu_Type = (id,userId)=>{
    return dispatch=>deleteType(id,userId).then(res=>{
        // if(res.status === 200){
            dispatch(deleteedutype(id))
        // }
        return res
    })
}


// Update Edu Type
export const UPDATE_EDU_TYPE = 'UPDATE_EDU_TYPE';

const updateedutype = (eduType)=>{
    return {
        type:UPDATE_EDU_TYPE,
        eduType
    }
}
export const Update_Edu_Type = (data,id,userId)=>{
    return dispatch=>updateType(data,id,userId).then(res=>{
        console.log(res)
        if(res.status === 200){
            dispatch(updateedutype(res?.data?.eduType))
        }
        return res
    })
}
export const Update_Edu_Type_branch = (id,branchId,userId)=>{
    return dispatch=>updateTypeForBranch(id,branchId,userId).then(res=>{
        console.log(res)
        if(res.status === 200){
            dispatch(updateedutype(res?.data?.eduType))
        }
        return res
    })
}




