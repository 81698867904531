import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoAddCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { Create_Main_Material } from '../../../actions/mainMaterial';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';

const AddMainMaterial = ({ item }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [name, setName] = useState("");
    const loggedUser = useSelector((state) => state.loggedUser)
    // console.log("FOr Items", item?._id)
    const dispatch = useDispatch();

    const handleAddMainMaterial = async () => {
        setLoading(true);
        try {
            const res = await dispatch(Create_Main_Material({
                name: name,
                levelId: item?._id
            }, loggedUser?.user?._id ,item?._id))
            if (res?.data?.case) {
                success(res?.data?.message)
            }
        } catch (err) {
            ErrorMsg(err?.response?.data?.message)
        } finally {
            setLoading(false);
            handleClose();
        }
    }

    return (
        <div>
            <IoAddCircleSharp onClick={handleShow} color="#0F2A51" size={30} />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Main Material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>Main Material Name:</label>
                        <input type="text" name="textMainMaterial" id="textMainMaterial" className='form-control'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddMainMaterial} disabled={loading}>
                        {loading ? 'loading...' : 'Add'}

                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddMainMaterial
