import React, { useEffect, useState, usseState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetMaterialsByIds } from '../../../utils/materials';
import AddMaterialLessons from './addMaterialLessons';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import Loading from '../../loadingEffect';
import { Create_New_Package } from '../../../actions/packages';
import axios from 'axios';
import { uploadFileLocaly, urlBase } from '../../../utils/API';
import "./createpackage.css"
import { useParams } from 'react-router-dom';
import { Get_Materials } from '../../../actions/curriculums/materials';


function CreatePackage({ currency, role }) {
    const [loading, setLoading] = useState();
    const params = useParams();
    const [message, setMessage] = useState(null);
    const [name, setName] = useState('');
    const [branchId, setBranchId] = useState(null);
    const [selectedLev,setSetSelectedLev] = useState([]);
    const [levelId, setLevelId] = useState(null);

    const [brLevels, setBrLevels] = useState([]);

    const [currentMaterials, setCurrentMaterials] = useState([])
    const [levelMaterials, setLevelMaterials] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [price, setPrice] = useState(null);
    const [discountPrice, setDiscountPrice] = useState(null);
    const [teacher, setTeacher] = useState(role === 'teacher' ? params.id : null);
    const [delayed, setDelayed] = useState(false);
    const [date, setDate] = useState("");
   

    const { branchs, levels, toast, user } = useSelector(({ branchs, levels, notify, loggedUser }) => {
        return { branchs, levels, toast: notify.toast, user: loggedUser?.user }
    })


    // console.log(user)


    const [description, setDescription] = useState(null)
    const [coverImage, setCoverImage] = useState(null)
    const dispatch = useDispatch();
    useEffect(() => {

    }, [])

    // on change selected branch 
    const onChangeBranch = (branchID) => {
        try {
            setBranchId(branchID)
            const branch = branchs.find(b => b._id === branchID)
            const levelsIDS = branch.levelsIDS ? branch.levelsIDS : []
            const branchLevels = levels.filter(l => levelsIDS.includes(l._id));
            setBrLevels(branchLevels);
            setLevelMaterials([]) // reset level materials
            setSetSelectedLev([]);
            setLevelId(null)
        }
        catch (err) {
            console.log(err.message)
        }
    }

    //    onChange level
    const onChangeLevel = async (levelID) => {
        try {
            setLevelId(levelID)
            const selectedLevel = levels.find((level) => level._id === levelID);
            
            // console.log('selected Level:', selectedLevel)
            const res = await GetMaterialsByIds({ IDS: selectedLevel.materialIDS });
            // console.log('res', res)
            setSetSelectedLev(selectedLevel);
            const { materials } = res.data;
            setCurrentMaterials(materials);
            setLevelMaterials(materials);
            setSelectedMaterials([]) // reset selected materials
        }
        catch (err) {
            console.log(err.message)
        }
    }

    // set Beanch of teacher
    // useEffect(()=>{
    //     if(user && user.role==='teacher'){
    //         onChangeBranch
    //     }
    // },[])
    //  onSelect matrials
    const onChangeMaterial = (materialID) => {
        try {
            if (!materialID || materialID === '') return;
            const material = currentMaterials.find(m => m._id === materialID)
            const selectedMaterialObject = { materialCode: material.code, selectedLessons: [] }
            setSelectedMaterials([...selectedMaterials, selectedMaterialObject]);
            // remove selected material from materials in select area
            const newLevelMaterials = levelMaterials.filter(m => m._id !== materialID);
            setLevelMaterials(newLevelMaterials);
        }
        catch (err) {
            console.log(err.message)
        }
    }
    // console.log('current Materials',currentMaterials)
    // on remove material
    const onRemoveMaterial = (code) => {
        try {
            // console.log('code',code);
            const newSelectedMaterial = selectedMaterials.filter(m => m.materialCode !== code);
            setSelectedMaterials(newSelectedMaterial)
            // add removed material into levelMaterials
            const removedMaterialObject = currentMaterials.find(l => l.code === code);
            const newLevelMaterials = [...levelMaterials, removedMaterialObject];
            setLevelMaterials(newLevelMaterials);
        }
        catch (err) {
            console.log(err.message)
        }
    }

    // onChange material lessons
    const onChangeMaterialLessons = (lessons, code) => {
        try {
            // console.log('ghjk',code,lessons)
            const newSelectedMaterial = selectedMaterials.map(m => {
                if (m.materialCode === code) {
                    const newMaterial = { materialCode: code, selectedLessons: lessons };
                    return newMaterial
                }
                else { return m };
            })
            // console.log('sele', newSelectedMaterial)
            setSelectedMaterials(newSelectedMaterial);
        }
        catch (err) {
            console.log(err.message)
        }
    }

    // Select Teacher Selector
    const teachers = useSelector(state => state.users.teachers);

    const onChangeTeacher = async (teacherId) => {
        setTeacher(teacherId);
    }

    const [progress, setProgress] = useState(0);
    const [image, setImage] = useState(null);
    const [cover, setCover] = useState(null);


    // Submit package
    // console.log('selectedMaterials',selectedMaterials)
    const handleSubmit = async (e) => {
        try {

            e.preventDefault();
            if (!name || !price || !discountPrice || !description || !branchId || !levelId || !selectedMaterials.length) return alert('من فضلك قم باكمال البيانات المطلوبة!')
            const data = { materials: selectedMaterials, price, discountPrice, delayed, name, branchId, levelId, description, teacher: teacher, startDate: date }
            // console.log('package' , data)
            setLoading(true);
            let upladCoverRes
            if (image) {

                upladCoverRes = await uploadingFileLocally(image);
                if (!upladCoverRes.data.case) throw new Error('خطا فى تحميل الملف!');
                data.coverImage = upladCoverRes?.data?.data.name;
            }
        
            const res = await dispatch(Create_New_Package(data,loggedUser?.user?._id));
            //   resetAllValues();
            if (res.data.case) {
                toast().success(res.data?.message)
            }
            else {
                toast().error(res.data?.message)
            }
            setMessage(res.data.message);
            // Dispatch actions or perform further actions here
            setLoading(false);

        }
        catch (err) {
            console.warn(err);
            toast().error(err?.message);
            setLoading(false)
        }
    }


    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${user?._id}`,
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentage)

            }
        })
    }

    const [materials , setMaterials]  = useState([]);

    const loggedUser = useSelector((state) => state.loggedUser);
    const {lang,dir} = useSelector(state=>state.language)
    useEffect(()=>{
        const data = async()=>{
            const res =await dispatch(Get_Materials());
            setMaterials(res?.data?.materials);
        }

        data();
    },[])

    console.log('sssss',selectedLev,levelMaterials)

    return (
        <Container className='loading-parent font' style={{ border: '0px solid red', width: '100%' }} dir={dir}>
            <h3>اضافة برنامج جديد</h3>

            {loading ? <Loading /> :
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Label> برنامج مؤجل  </Form.Label>

                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={delayed}
                                onChange={() => { setDelayed(!delayed); }}
                            />
                            <span className="slider round"></span>
                        </label>

                        <Col md={12}>
                            <Form.Group controlId="materialSelect">
                                <Form.Label>اسم البرنامج الدراسى </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='ادخل اسم البرنامج'
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        {/* Description */}
                        <Col md={12}>
                            <Form.Group controlId="materialSelect">
                                <Form.Label>الوصف</Form.Label>
                                <Form.Control
                                    type="textarea"
                                    placeholder='ادخل وصف الباقة'
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        {/* Delayed */}
                        <Col md={12}>
                            <Form.Group controlId="program-cover-image">
                                <Form.Label>اختر تاريخ البرنامج المؤجل </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dateDelayed"
                                    id="dateDelayed"
                                    onChange={(e) => {
                                        setDate(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        {/* Upload Cover Image */}

                        <Col md={12}>
                            <Form.Group controlId="program-cover-image">
                                <Form.Label>صورة البرنامج </Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(event) => {
                                        setImage(event.target.files[0]);
                                        const url = window.URL.createObjectURL(event.target.files[0]);
                                        setCover(url)

                                    }}
                                />
                            </Form.Group>
                            {cover ?
                                <img width="300px" src={cover} alt="imageprogram" /> : <></>}
                        </Col>
                        {/* Select Teacher */}
                        {role !== 'teacher' && (
                            <Col md={12}>
                                <Form.Group controlId="levelSelect">
                                    <Form.Label> اختر معلم</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => onChangeTeacher(e.target.value)}
                                    >
                                        <option value="">اختر معلم</option>
                                        {teachers.map((teacher) => (
                                            <option key={teacher._id} value={teacher._id}>
                                                {teacher.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        )}
                    
                        <Col md={6}>
                            <Form.Group controlId="price">
                                <Form.Label>السعر بدون خصم {currency ? currency : 'ج.م'}</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder='سعر الكورس بدون خصم '
                                    onChange={(e) => setPrice(e.target.value)}

                                />
                            </Form.Group>


                        </Col>
                        <Col md={6}>

                            <Form.Group controlId="discountPrice">
                                <Form.Label>السعر بعد الخصم {currency ? currency : 'ج.م'}</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder='سعر الكورس بعد الخصم '
                                    onChange={(e) => setDiscountPrice(e.target.value)}

                                />
                            </Form.Group>

                        </Col>
                            {/* select branch */}
                            <Col md={12}>
                            <Form.Group controlId="levelSelect">
                                <Form.Label> الفئة</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => onChangeBranch(e.target.value)}
                                >
                                    <option value="">اختر الفئة</option>
                                    {branchs
                                        
                                        .map((br) => (
                                            <option key={br._id} value={br._id}>
                                                {br.name}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        {/* select level */}

                        <Col md={12}>
                            <Form.Group controlId="levelSelect">
                                <Form.Label> المستوى</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => onChangeLevel(e.target.value)}
                                >
                                    <option value="">اختر المستوى</option>
                                    {brLevels.map((level) => (
                                        <option key={level._id} value={level._id}>
                                            {level.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {
                            role !== "teacher"?(
                                <Col md={6}>
                                <Form.Group controlId="materialSelect">
                                    <Form.Label>المواد</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => onChangeMaterial(e.target.value)}
                                    >
                                        <option value={null}> اختر المادة </option>
                                        {levelMaterials?.map((material) => (
                                            <option key={material?.code} value={material?._id}>
                                                {material?.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            ):null
                        }

                        {
                            role === "teacher"?(
                                <Col md={6}>
                                <Form.Group controlId="materialSelect">
                                    <Form.Label>المواد</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => onChangeMaterial(e.target.value)}
                                    >
                                        <option value={null}> اختر المادة </option>
                                        {materials?.filter((material)=> material?.teacherId?._id === loggedUser?.user?._id && selectedLev?.materialIDS?.includes(material?._id)).map((material) => (
                                            <option key={material?.code} value={material?._id}>
                                                {material?.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            ):null
                        }

                        {selectedMaterials.map(s =>
                            <>
                                <AddMaterialLessons
                                    material={s}
                                    onChangeMaterialLessons={onChangeMaterialLessons}
                                    onRemoveMaterial={onRemoveMaterial}
                                />
                                <hr />
                            </>
                        )}

                    </Row>
                    {message ? <Alert>{message}</Alert> : <></>}

                    <Button type="submit" style={{ marginTop: '20px' }}>
                        انشاء الكورس
                    </Button>
                </Form>
            }
        </Container>
    )
}

export default CreatePackage


