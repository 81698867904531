import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import visa from "../../../assets/visa.png"
import card from "../../../assets/masterCard.png"
import fawry from "../../../assets/fawry.png"
import { useSelector } from 'react-redux';
import { userSubscripePackage } from '../../../utils/packagesubscriptions';
import { ErrorMsg, success } from '../../shared/modules/Notifications';
import { HomePage } from '../../../utils/Translation/HomePage';


function Subscripe({ sub }) {

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShowModal(false);

    const handleShow = () => {
        setShowModal(true);
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const loggedUser = useSelector((state) => state.loggedUser);




    // Payment Handle
    const payMentHandle = async (e) => {
        try {
            if (loggedUser?.auth) {
                const res = await userSubscripePackage({
                    subscriptionPeriod: e // monthly | annually
                }, sub?._id, loggedUser?.user?._id)
                console.log(res)
                if (res?.data?.case) {
                    success(res?.data?.message);
                    if (res?.data?.paymentData?.hppUrl) {
                        window.location.href = `${res?.data?.paymentData?.hppUrl}`
                        handleClose();
                    } else {
                        success("تم الاشتراك بالنجاج");
                        handleClose();
                    }

                }

            } else {
                navigate("/login")
                ErrorMsg("قم بتسجيل الدخول")
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        }
    }
    return (
        <div>
            <Button style={{ background: "#4169E2" }} onClick={handleShow} className="start-button">
                اختر الباقه
            </Button>


            <Modal show={showModal} onHide={handleClose} centered size='lg' className='font' dir={dir}>
                <Modal.Body >
                    <div className='row justify-content-between align-items-start'>
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 '>
                            <div>
                                <h3>بوابة الدفع</h3>
                                <img src={fawry} alt="ImagedA" className='imagePayMent w-25' />
                                <img src={visa} alt="ImageDA" className='imagePayMent w-25' />
                                <img src={card} alt="ImageDA" className='imagePayMent w-25' />
                            </div>
                            <div clasName="mt-3">
                                <p>{HomePage.serviceType[lang]} : {sub?.packageType}</p>
                                <p>{HomePage.serviceName[lang]} : {sub?.name}</p>
                                <ul className="package-features">
                                    <li className="feature active">{HomePage.pricemonthDir[lang]}:{sub?.monthlyPrice} {HomePage.egp[lang]}</li>
                                    <li className="feature active">{HomePage.annualPrice[lang]}:{sub?.annuallyPrice}{HomePage.egp[lang]}</li>
                                    <li className="feature inactive">{HomePage.maxValue[lang]}:{sub?.maxValue}{HomePage.studetn[lang]}</li>
                                    <li className="feature inactive">{HomePage.trialDuration[lang]}:{sub?.trialDuration}{HomePage.today[lang]}</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-6 col-sm-12 col-12'>
                            <p>الحساب النهائي</p>
                            <div>
                                <h5>باقة {sub?.name}</h5>
                                <h6 className="mt-5">{HomePage.pricemonthDir[lang]}:{sub?.monthlyPrice} {HomePage.egp[lang]}</h6>
                                <h6 className="mt-5">{HomePage.priceAnual[lang]}:{sub?.annuallyPrice}{HomePage.egp[lang]}</h6>
                            </div>
                            <div>

                            </div>
                            <div>

                            </div>

                            <div className='mt-5'>
                                <Button variant="danger" onClick={handleClose}>
                                    الغاء
                                </Button>
                                <Button variant="primary" className='mx-3' onClick={() => { payMentHandle("monthly") }}>
                                    الدفع الشهري
                                </Button>
                                <Button variant="primary" className='mx-3' onClick={() => { payMentHandle("annually") }}>
                                    الدفع السنوي
                                </Button>

                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>



        </div>
    );
}

export default Subscripe;
