import { CREATE_ALL_PACKAGES_SUPSCRIPTIONS, DELETE_PACKAGES_SUPSCRIPTIONS, GET_ALL_PACKAGES_SUPSCRIPTIONS, UPDATE_PACKAGES_SUPSCRIPTIONS } from "../actions/packagesubscriptions";

export const packagessubscriptions = (state = [], action) => {
    switch (action.type) {
        case GET_ALL_PACKAGES_SUPSCRIPTIONS:
            return state = action.packages
        case CREATE_ALL_PACKAGES_SUPSCRIPTIONS:
            console.log(action)
            return state = [...state, action.packages]
        case UPDATE_PACKAGES_SUPSCRIPTIONS:
            console.log(action)
            return state = state.map(s => {
                if (s._id === action.id) {
                    return action.packages
                }
                return s
            })
        case DELETE_PACKAGES_SUPSCRIPTIONS:
            console.log(action)
            return state = state.filter(s => s._id !== action.packages.id)
        default: return state
    }
}

