import { urlBase } from "./API";
import axios from "axios";

export class FileUploader {
    constructor(userID, file) {
        this.userID = userID;
        this.file = file;
    }

    async uploadFile(service) {
        try {
            switch (service) {
                case 'LOCAL':
                    return await this.uploadLocally();
                case 'AWS-S3':
                    return await this.uploadToS3();
                case 'link':
                    return this.addAsLink();
                default:
                    throw new Error("Unsupported service type");
            }
        } catch (err) {
            console.warn("File upload error:", err);
            throw err;
        }
    }

    async uploadLocally() {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("user", this.userID);

        return await axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${this.userID}`,
            data: formData,
            onUploadProgress: (progress) => {
                console.log(`Local Upload Progress: ${(progress.loaded / progress.total) * 100}%`);
            }
        });
    }

    // async uploadToS3() {
    //     const presignedUrlResponse = await axios({
    //         method: "POST",
    //         url: `${urlBase}/api/generate-presigned-url?userId=${this.userID}`,
    //         data: { fileName: this.file?.name, fileSize: this.file?.size }
    //     });

    //     const { url, contentType } = presignedUrlResponse?.data;

    //     return await axios.put(url, this.file, {
    //         headers: { "Content-Type": contentType },
    //         onUploadProgress: (progress) => {
    //             console.log(`AWS-S3 Upload Progress: ${(progress.loaded / progress.total) * 100}%`);
    //         }
    //     });
    // }
    async uploadToS3() {
        const presignedUrlResponse = await axios({
            method: "POST",
            url: `${urlBase}/api/generate-presigned-url?userId=${this.userID}`,
            data: { fileName: this.file?.name, fileSize: this.file?.size }
        });

        const { url, contentType } = presignedUrlResponse?.data;

        const uploadResponse = await axios.put(url, this.file, {
            headers: { "Content-Type": contentType },
            onUploadProgress: (progress) => {
                console.log(`AWS-S3 Upload Progress: ${(progress.loaded / progress.total) * 100}%`);
            }
        });

        if (uploadResponse.status === 200) {
            const mediaData = {
                size: this.file.size,
                name: presignedUrlResponse?.data?.fileName,
                service: "aws",
                preview: this.file.name
            };

            const mediaSave = await axios.post(`${urlBase}/api/medias/save-media?userId=${this.userID}`, mediaData);
            return mediaSave;
        }

        return uploadResponse;
    }

    addAsLink() {
        return { message: "File will be added as a link, no upload required." };
    }
}

export default FileUploader;

