import React, { useEffect } from 'react'
import PageContainer from '../../components/layout/PagecContainer'
import HomeHeader from '../../components/Home/HomeHeader'
import OurPlatform from '../../components/Home/OurPlatform'
import AboutPlatform from '../../components/Home/AboutPlatform'
import Programs from '../../components/Home/Programs'
import JoinUs from '../../components/Home/JoinUs'
import Teachers from '../../components/Home/Teachers'
import { useSelector } from 'react-redux';
import Chat from '../../components-old/chat'
import { useLocation } from 'react-router-dom'
import JoinUsTeaher from '../../components/Home/JoinUsTeaher'
import ConversationModal from '../../components/modals/ConversationModal'
const HomePage = () => {
    const packages = useSelector((state) => state.packages);
    const location = useLocation();
    useEffect(() => {
        // حفظ المسار الحالي في localStorage
        localStorage.setItem("lastRoute", location.pathname);
    }, [location]);



    return (
        <div className='font'>
            <PageContainer>
                <Chat />
                <ConversationModal title={"المساعد النفسي"} />
                <main id="home" style={{ marginTop: "100px" }}>
                    <HomeHeader />

                    <OurPlatform />

                    <AboutPlatform />

                    <Programs packages={packages} />

                    <JoinUs />

                    <Teachers />

                    <JoinUsTeaher />
                </main>
            </PageContainer>
        </div>
    )
}

export default HomePage
