import React, { useEffect } from 'react'
import PageContainer from '../../components/layout/PagecContainer'
import ServiceType from '../../components/TeacherServices/ServiceType'
import "../../styles/teacher.css"
import { useDispatch, useSelector } from 'react-redux'
import { Get_All_Packages_Subscriptions } from '../../actions/packagesubscriptions'

const TeacherServicePage = () => {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(Get_All_Packages_Subscriptions())
    },[])


    
    return (
        <div>
            <PageContainer>
                <div style={{ marginTop: "130px" }}>
                    <ServiceType />
                </div>
            </PageContainer>
        </div>
    )
}

export default TeacherServicePage
