

import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import ModalAddServices from './ModalAddServices';
import ModalDeleteService from './ModalDeleteService'; // Import the delete modal
import { MdDelete } from "react-icons/md";
import UpdateServiceModal from './UpdateServiceModal';
import "./service.css";
import { Get_All_Packages_Subscriptions, Update_Packages_Subscriptions } from '../../../actions/packagesubscriptions';

const Services = () => {
    useEffect(() => {
        dispatch(Get_All_Packages_Subscriptions())
    }, [])
    const packageSubs = useSelector((state) => state.packagessubscriptions);
    const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control delete modal
    const [selectedPackageId, setSelectedPackageId] = useState(null); // Store package id for deletion
    const dispatch = useDispatch();

    const handleShowDeleteModal = (id) => {
        setSelectedPackageId(id); // Set the selected package ID
        setShowDeleteModal(true); // Show the delete modal
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false); // Close the modal
        setSelectedPackageId(null); // Clear the selected package ID
    };

    // Custom Switch Button with update logic
    const SwitchButton = ({ status, sub, packageId }) => {
        console.log(status)
        console.log(packageId)
        const [isChecked, setIsChecked] = useState(status === 'active');

        const handleToggle = async () => {
            const newStatus = isChecked ? 'inactive' : 'active'; // Toggle status
            setIsChecked(!isChecked);

            try {
                const res = await dispatch(Update_Packages_Subscriptions({
                    ...sub,
                    status: newStatus
                }, packageId))
                console.log(res)
            } catch (error) {
                console.log('Error updating package status:', error);
            }
        };

        return (
            <label className="switch">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleToggle}
                />
                <span className="slider round"></span>
            </label>
        );
    };

    console.log(packageSubs)

    return (
        <div>
            <ModalAddServices />
            <Table responsive className='font mt-5'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>اسم الباقه</th>
                        <th>تاريخ انشاء الباقه</th>
                        <th>نوع الباقه</th>
                        <th>الاشتراك السنوي</th>
                        <th>الاشتراك الشهري</th>
                        <th>اقصي عدد</th>
                        <th>الفتره التجريبيه</th>
                        <th>حالة الباقه</th>
                        <th>تعديل</th>
                        <th>حذف</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        packageSubs?.map((sub, index) => (
                            <tr key={sub._id}>
                                <td>{index + 1}</td>
                                <td>{sub?.name}</td>
                                <td>{sub?.createdAt}</td>
                                <td>{sub?.packageType}</td>
                                <td>{sub?.annuallyPrice}</td>
                                <td>{sub?.monthlyPrice}</td>
                                <td>{sub?.maxValue}</td>
                                <td>{sub?.trialDuration}</td>
                                <td>
                                    <SwitchButton
                                        status={sub?.status}
                                        sub={sub}
                                        packageId={sub?._id} // Pass package ID for update
                                    />
                                </td>
                                <td>
                                    <UpdateServiceModal subId={sub._id} />
                                </td>
                                <td>
                                    <MdDelete
                                        size={"20px"}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleShowDeleteModal(sub._id)} // Show modal on delete click
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            {/* Delete modal */}
            <ModalDeleteService
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                packageId={selectedPackageId}
            />
        </div>
    );
};

export default Services;
