import React, { useEffect, useState } from 'react'
import PageContainer from '../../layout/PagecContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { HomePage } from '../../../utils/Translation/HomePage';
import login_Rectangle from "../../../assets/register_image.png";
import { loginTrans } from '../../../utils/Translation/loginAndRegister';
import "./styles/register.css";
import { Alert } from 'react-bootstrap';
import { Register_Student_Parent } from '../../../actions/login&register';
import { logIn } from "../../../actions/login&register";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function RegisterPage() {
    const [isParent, setIsParent] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { lang, dir } = useSelector(({ language }) => ({ lang: language.lang, dir: language.dir }));
    const { branchs, levels, toast } = useSelector(({ branchs, levels, notify }) => ({ branchs, levels, toast: notify.toast }));

    const [Levels, setLevels] = useState([]);
    const [isPasswordSame, setIsPasswordSame] = useState();
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState("password");
    const [stPassConfirm, setStPassConfirm] = useState(false);
    const [parPassConfirm, setParPassConfirm] = useState(false);
    const [notRebote, setNotRebote] = useState(false);
    const [validValues, setValidValues] = useState({
        username: null,
        // email:true,
        // phone:true
    })
    const [formData, setFormData] = useState({
        email: null,
        password: null,
        confirmpassword: null,
        studentName: null,
        username: null,
        type: '',
        studentPhone: null,
        category: null,
        levelId: null,
        parentEmail: null,
        parentName: null,
        parentUsername: null,
        parentPhone: null,
        parentPassword: null,
        confirmParentPassword: null,
    });

    //    console.log(branchs)    // Get branch "category" levels
    useEffect(() => {
        if (formData.category) {
            const [branch] = branchs.filter(b => b?._id === formData?.category)
            const categoryLevels = levels.filter(l => branch?.levelsIDS?.includes(l?._id));
            setLevels(categoryLevels);
        }
    }, [formData?.category])
    // setMessage 
    useEffect(() => {
        setTimeout(() => {
            setMessage(false)
        }, 5000)

    }, [message])

    // verify password   
    const verifyPass = (pass1, pass2) => {
        if (pass1 === pass2) {
            return true
        }
        return false
    }
    // onChange field value
    // console.log('sssssssssss')
    function isValidUsername(username) {
        // Regular expression to check for spaces, backslashes, Arabic characters, or dots
        const invalidUsernameRegex = /[\s\\.\/\\\u0600-\u06FF]/;

        // Test if the username contains any of these characters
        return !invalidUsernameRegex.test(username);
    }
    const onChange = (key, value) => {
        setFormData(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
        if (key === "confirmpassword") {
            const verification = verifyPass(formData?.password, value);
            if (verification) {
                setStPassConfirm(true)
            }
            else {
                setStPassConfirm(false)
            }
        }
        if (key === "password") {
            const verification = verifyPass(formData?.confirmpassword, value);
            if (verification) {
                setStPassConfirm(true)
            }
            else {
                setStPassConfirm(false)
            }
        }
        if (key === "parentPassword") {
            const verification = verifyPass(formData?.confirmParentPassword, value);
            if (verification) {
                setParPassConfirm(true)
            }
            else {
                setParPassConfirm(false)
            }
        }
        if (key === "confirmParentPassword") {
            const verification = verifyPass(formData?.parentPassword, value);
            if (verification) {
                setParPassConfirm(true)
            }
            else {
                setParPassConfirm(false)
            }
        }
        if (key === "username") {
            const check = isValidUsername(value)
            setValidValues(prev => {
                return { ...prev, username: check }
            })

        }
    }
    // console.log('formData', formData);


    // submit function
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (!stPassConfirm) {
                return;
            }
            if (isParent && !isPasswordSame && !parPassConfirm) {
                return
            }
            if (!validValues.username) { return; }
            const studentData = {
                email: formData?.email,
                password: formData?.password,
                // gender: formData?.type==="1" ||formData?.type==="0"?"male":"female",
                role: "student",
                // branchId:formData?.category,
                username: formData?.username,
                phone: `+${formData?.studentPhone}`,
            }
            const parentData = {
                email: formData?.parentEmail,
                password: isPasswordSame ? formData?.password : formData?.parentPassword,
                role: "parent",
                username: formData?.parentUsername,
                name: formData?.parentName,
                phone: `+${formData?.parentPhone}`
            }
            setLoading(true);
            const res = await dispatch(Register_Student_Parent({
                student: studentData,
                parent: isParent ? parentData : null,
                lang
            }))
            if (res?.data?.case) {
                toast().success(lang === 'AR' ? 'تم التسجيل بنجاح' : 'Registered successfully')
                setLoading(false);
                toast().success(lang === "AR" ? "جارى تسجيل الدخول ..." : "Sign in ...")
                const loginRes = await dispatch(logIn({
                    email: studentData?.email,
                    password: studentData?.password
                }))
                if (loginRes.auth) {
                    navigate(`/dashboard/${res?.username}/${res?.user?._id}/subscriptions`);

                }
            }
            else {
                toast().error(res?.data?.message)
            }
            setLoading(false)
        }
        catch (err) {
            console.warn(err);
            toast().error(err.message)
            setLoading(false)
        }
    }

    const studentFields = [
        { name: 'studentName', type: 'text', placeholder: ``, label: loginTrans.student_name[lang], required: true },

        {
            name: 'email', type: 'email', placeholder: "example@gmail.com", label: loginTrans.email[lang], required: true
        },
        { name: 'username', type: 'text', placeholder: `example: Ahmed_Ali`, label: loginTrans.user_name[lang], required: true, hint: loginTrans.username_hint[lang] },
        {
            name: 'studentPhone',
            type: 'phone',
            label: loginTrans.student_phone[lang],
            required: true
        },
        // { name: 'studentPhone', type: 'tel', placeholder: "02 01x xxx xxxxx", label: loginTrans.student_phone[lang], required: true },
        // { name: 'category', type: 'select', placeholder: loginTrans.select_level[lang], label: loginTrans.select_level[lang], required: true },
        // {name:levelId,type:"select",placeholder:loginTrans.select_level[lang]},
        // { name: 'type', type: 'select', placeholder: loginTrans.select_gender[lang], label: loginTrans.select_level[lang], required: true },
        { name: 'password', type: 'password', placeholder: loginTrans.pass[lang], label: loginTrans.pass[lang], required: true },
        { name: 'confirmpassword', type: 'password', placeholder: loginTrans.pass_confirm[lang], label: loginTrans.pass_confirm[lang], required: true },
    ]

    const parentFields = [
        { name: 'parentName', type: 'text', placeholder: loginTrans.parent_name[lang], label: loginTrans.parent_name[lang], required: true },
        { name: 'parentUsername', type: 'text', placeholder: loginTrans.parent_username[lang], label: loginTrans.parent_username[lang], required: true },
        { name: 'parentEmail', type: 'email', placeholder: loginTrans.email[lang], label: loginTrans.email[lang], required: true },
        {
            name: 'parentPhone',
            type: 'phone',
            label: loginTrans.father_phone[lang],
            required: true
        },
        // { name: 'parentPhone', type: 'tel', placeholder: loginTrans.father_phone[lang], label: loginTrans.father_phone[lang], required: true },
        { name: 'parentPassword', type: 'password', placeholder: loginTrans.pass[lang], label: loginTrans.pass[lang], required: true },
        { name: 'confirmParentPassword', type: 'password', placeholder: loginTrans.pass_confirm[lang], label: loginTrans.pass[lang], required: true },

    ]


    const showPassword = () => {
        if (showPass === "password") {
            setShowPass("text")
        }
        else if (showPass === "text") {
            setShowPass("password")
        }
    }
    // console.log('stConfirm',stPassConfirm)
    return (
        <PageContainer>
            <div className="registerPage" dir={dir}>
                <div className="container spacePage">
                    {/* the login form and image and Parent and student Tabs*/}
                    <div className="registerStudentForm pb-4 font">
                        <div className="font text-center">
                            <p className="color">{HomePage.welcomeSentence[lang]}</p>
                            <p>{HomePage.writeDetails[lang]}</p>
                        </div>

                        <div className="px-4">
                            {!loading ? (
                                <>
                                    {/* submite message */}
                                    {
                                        message && <div className={`mt-3 text-center alert ${message?.case ? 'alert-success' : 'alert-danger'}`} role="alert">
                                            {message && message?.message}
                                        </div>
                                    }

                                    {/* Form Body  */}
                                    <div className="formBody" >
                                        <div className="row justify-content-between align-items-center">

                                            <div className="col-lg-6 col-sm-6 col-md-6 col-12">
                                                <div className=" formBorder">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="shadow formregister">
                                                            {/* student email */}
                                                            {studentFields?.map((field, index) => {
                                                                if (field.type === "select" && field.name === "category") {
                                                                    return (
                                                                        <div className="mb-4 register_mdi_password_relative">
                                                                            <select
                                                                                className="form-select"
                                                                                aria-label="Default select example"
                                                                                name="category"
                                                                                id="registerPage23"
                                                                                value={formData[field?.name]}
                                                                                required={formData?.required}
                                                                                onChange={(event) => {
                                                                                    onChange(event.target.name, event.target.value)

                                                                                }}
                                                                            >
                                                                                <option selected>{loginTrans.select_level[lang]}</option>
                                                                                {branchs.map(b => <option key={b._id} value={b._id}>{b.name}</option>)}
                                                                            </select>

                                                                        </div>
                                                                    )
                                                                }
                                                                else if (field.type === 'select' && field.name === "type") {
                                                                    return (
                                                                        <div className="mb-4 register_mdi_password_relative">
                                                                            <select
                                                                                className="form-select"
                                                                                aria-label="Gender"
                                                                                name="type"
                                                                                id="registerPage351"
                                                                                value={formData[field?.name]}
                                                                                onChange={(event) => {
                                                                                    onChange(event.target.name, event.target.value)

                                                                                }}
                                                                            >
                                                                                <option value="0">{loginTrans.select_gender[lang]}</option>
                                                                                <option value="1">{loginTrans.male[lang]}</option>
                                                                                <option value="2">{loginTrans.female[lang]}</option>
                                                                            </select>

                                                                        </div>
                                                                    )
                                                                } else if (field.type === "phone") {
                                                                    return (
                                                                        <div className="mb-4" key={index}>
                                                                            <label>{field.label}</label>
                                                                            <PhoneInput
                                                                                country={'eg'}
                                                                                style={{ width: "100%" }}
                                                                                value={formData[field.name]}
                                                                                onChange={(phone) => onChange(field.name, phone)}
                                                                                inputProps={{
                                                                                    required: field.required,
                                                                                    className: 'form-control'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                return (
                                                                    <div className="mb-4" key={index}>
                                                                        <label dir={dir}>{field?.label}</label>

                                                                        <input
                                                                            type={field?.type === "password" ? showPass : field?.type}
                                                                            name={field?.name}
                                                                            className={`form-control ${field?.name === "username" && validValues.username === false ? "error_field" : ''}`}
                                                                            placeholder={field?.placeholder}
                                                                            aria-describedby="example@gmail.com"
                                                                            value={formData[field?.name]}
                                                                            required={formData?.required}
                                                                            onChange={(event) =>
                                                                                onChange(event.target.name, event.target.value)

                                                                            }
                                                                        />
                                                                        {field?.hint ? <span className='hint'>{field?.hint}</span> : <></>}
                                                                        {field?.name === "confirmpassword" && formData.confirmpassword ?
                                                                            <>
                                                                                {stPassConfirm ? <Alert variant='success'>"Matched"</Alert> : <Alert variant='danger'>"Not matched"</Alert>}
                                                                            </>
                                                                            : <></>
                                                                        }
                                                                        {/* {field?.name==="username"&&validValues.username!==null?
                                                                        <>
                                                                        {validValues?.username===false?<span></span>:""}
                                                                        </>:<></>} */}

                                                                    </div>
                                                                )
                                                            }
                                                            )
                                                            }

                                                            {/* Parent Part */}
                                                            {/* parent data */}
                                                            <div className="mb-4 register_mdi_password_relative">
                                                                <input type="checkbox"
                                                                    checked={isParent ? true : false}
                                                                    onChange={() => setIsParent(!isParent)}
                                                                />
                                                                <label>{loginTrans.create_parent_account[lang]}</label>
                                                            </div>
                                                            {isParent ? parentFields?.map((field, index) => {
                                                                return (<>
                                                                    <div className="mb-4" key={index}>


                                                                        {
                                                                            field.type === "phone" ? (
                                                                                <div className="mb-4" key={index}>
                                                                                    <label>{field.label}</label>
                                                                                    <PhoneInput
                                                                                        country={'eg'}
                                                                                        style={{ width: "100%" }}
                                                                                        value={formData[field.name]}
                                                                                        onChange={(phone) => onChange(field.name, phone)}
                                                                                        inputProps={{
                                                                                            required: field.required,
                                                                                            className: 'form-control'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <label>{field?.label}</label>
                                                                                    <input
                                                                                        type={field?.type === "password" ? showPass : field?.type}
                                                                                        name={field?.name}
                                                                                        className="form-control"
                                                                                        placeholder={field?.placeholder}

                                                                                        aria-describedby="example@gmail.com"
                                                                                        value={formData[field?.name]}
                                                                                        required={true}
                                                                                        onChange={(event) => {
                                                                                            onChange(event.target.name, event.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    {field?.name === "confirmParentPassword" && formData.confirmParentPassword ?
                                                                        <>
                                                                            {parPassConfirm ? <Alert variant='success'>"Matched"</Alert> : <Alert variant='danger'>"Not matched"</Alert>}
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                </>)

                                                            })
                                                                : <></>
                                                            }
                                                            <div>

                                                                <input type='checkbox' onChange={() => showPassword()} />
                                                                <label>{loginTrans.show_pass[lang]}</label>
                                                            </div>
                                                            <hr />
                                                            <div className="rules">
                                                                <p className="rulesPara1" id="registerPage15">

                                                                    <input type="checkbox"
                                                                        onChange={() => setNotRebote(!notRebote)}
                                                                        name="policy" id="policy" className="mx-2" />

                                                                    {loginTrans.terms_1[lang]} {loginTrans.terms_2[lang]}
                                                                    &nbsp;
                                                                    <span>{loginTrans.and[lang]}</span>
                                                                    &nbsp;
                                                                    {loginTrans.privacy[lang]}
                                                                </p>
                                                            </div>

                                                            {/* Submit */}
                                                            <div className="btnDone text-center mt-5">
                                                                {
                                                                    message && <div className={`mt-3 text-center alert ${message?.case ? 'alert-success' : 'alert-danger'}`} role="alert">
                                                                        {message && message?.message}
                                                                    </div>
                                                                }
                                                                <input
                                                                    type="submit"
                                                                    style={{ border: "none", padding: "10px", borderRadius: "10px", color: "white", outline: "none" }}
                                                                    className="background w-75"
                                                                    value={loginTrans.completed[lang]}
                                                                    disabled={!notRebote}
                                                                />

                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <div className="font">
                                                                    <span>{loginTrans.accountHas[lang]}</span>
                                                                    <br />
                                                                    <Link to="/login">{loginTrans.subscripe[lang]}</Link>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-md-6 col-12">
                                                <div className="imagePart">
                                                    <img src={login_Rectangle} className="login-image w-100 h-100" alt="error" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) :
                                // loading 
                                <div className="loaderIcon">
                                    <span className="loader"></span>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    )
}

export default RegisterPage