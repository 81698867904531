import React from 'react'
import ServiceTeacher from '../../components/TeacherServices/ServiceTeacher'
import PageContainer from '../../components/layout/PagecContainer'

const ServicesPages = () => {
    return (
        <div>
            <PageContainer>
                <div style={{ marginTop: "130px" }}>
                    <ServiceTeacher />
                </div>
            </PageContainer>
        </div>
    )
}

export default ServicesPages
