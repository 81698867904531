import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { FaRegEye } from "react-icons/fa";
import GetLessonById from './GetLessonById';
import { getallSessionByLesson } from '../../../../utils/sessions';
import { success } from '../../../../components/shared/modules/Notifications';
import AddHw from './AddHw';

const ShowSessions = ({ cls }) => {
    const [show, setShow] = useState(false);
    const [showSessions, setShowSessions] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [lessonId, setLessonId] = useState("");
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedLessons, setSelectedLessons] = useState([]);

    const handleMaterialChange = (e) => {
        const materialCode = e.target.value;
        setSelectedMaterial(materialCode);
        const material = cls?.packID?.materials.find(m => m.materialCode === materialCode);
        setSelectedLessons(material?.selectedLessons || []);
    }

    const handleGetSessionbyLesson = async () => {
        try {
            if (lessonId) {
                const res = await getallSessionByLesson(lessonId);
                if (res?.data?.case) {
                    setSessions(res?.data?.sessions);
                    setShow(false);
                    setShowSessions(true);
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const [modalHw, setModalHw] = useState(false);
    const [sessionId, setSessionId] = useState("");
    const modalOpenHW = (sessionId) => {
        setShowSessions(false);
        setSessionId(sessionId);
        setModalHw(true);
    }

    return (
        <div>
            <div className="w-100 text-center" onClick={() => setShow(true)} >
                <FaRegEye />
            </div>

            {/* First Modal for Selection */}
            <Modal show={show} onHide={() => setShow(false)} centered className='font'>
                <Modal.Body>
                    <div>
                        <select className="form-control" onChange={handleMaterialChange}>
                            <option hidden>Select Material</option>
                            {cls?.packID?.materials.map(m => (
                                <option key={m.materialCode} value={m.materialCode}>{m.materialCode}</option>
                            ))}
                        </select>
                    </div>
                    {selectedMaterial && (
                        <div className="mt-3">
                            <select className="form-control" value={lessonId} onChange={(e) => setLessonId(e.target.value)}>
                                <option hidden>Select Lesson</option>
                                {selectedLessons.map(lesson => (
                                    <option key={lesson} value={lesson}>
                                        <GetLessonById lesson={lesson} />
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShow(false)}>Cancel</button>
                    <button className="btn btn-primary" disabled={!lessonId} onClick={handleGetSessionbyLesson}>Show Sessions</button>
                </Modal.Footer>
            </Modal>

            {/* Second Modal for Sessions Display */}
            <Modal show={showSessions} onHide={() => setShowSessions(false)} centered className='font' size="xl">
                <Modal.Header>
                    <Modal.Title>Sessions List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead style={{ textAlign: "center" }}>
                            <tr>
                                <th>Session Name</th>
                                <th>Material Code</th>
                                <th>Homework Task</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th>Host Email </th>
                                <th>Join Student</th>
                                <th>Join Teacher</th>
                                <th>Status </th>
                                <th>Add Hw</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {sessions?.map((session) => (
                                <tr key={session._id}>
                                    <td>{session.name}</td>
                                    <td>{session.materialCode}</td>
                                    <td>{session.homeworkTask}</td>
                                    <td>{session.description}</td>
                                    <td>{new Date(session.date).toLocaleDateString()}</td>
                                    <td>{session?.zoom?.host_email || "لا يوجد لينك زووم"}</td>
                                    <td>
                                        {session?.zoom?.join_url ? (
                                            <span
                                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(session.zoom.join_url);
                                                    success('تم نسخ الرابط بنجاح!');
                                                }}
                                                title={session.zoom.join_url}
                                            >
                                                {session.zoom.join_url.substring(0, 10)}...
                                            </span>
                                        ) : "لا يوجد لينك زووم"}
                                    </td>
                                    <td>
                                        {session?.zoom?.start_url ? (
                                            <span
                                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(session.zoom.start_url);
                                                    success('تم نسخ الرابط بنجاح!');
                                                }}
                                                title={session.zoom.start_url}
                                            >
                                                {session.zoom.start_url.substring(0, 10)}...
                                            </span>
                                        ) : "لا يوجد لينك زووم"}
                                    </td>

                                    <td>{session?.zoom?.status || "لا يوجد لينك زووم"}</td>
                                    <td>
                                        <button className="btn btn-primary"
                                            onClick={() => modalOpenHW(session._id)}
                                        >Add Hw</button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            <AddHw show={modalHw} handleClose={() => setModalHw(false)} sessionId={sessionId} />
        </div>
    )
}

export default ShowSessions
