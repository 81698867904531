import React from 'react'
import "../../styles/joinusteacher.css"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
const JoinUsTeaher = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className='joinUsTeacher'>
                <h1 className='mt-4'>{HomePage.joinUsTeacher[lang]}</h1>
                <h2 className='mt-4'>{HomePage.us[lang]}</h2>
                <p className='mt-4'>{HomePage.joinUsTeacherData[lang]}</p>

                <Link to="/teacher-services" className='btn' style={{ backgroundColor: "#120C54", color: "white", borderRadius: "10px" }}>  {HomePage.teacherService[lang]}
                </Link>
            </div>
        </div>
    )
}

export default JoinUsTeaher