import { createMaterial, deleteMaterial, getMaterials, updateMaterial } from "../../utils/materials";

export const CREATE_MATERIAL = "CREATE_MATERIAL";

const create_Material = (material)=>{
    return{
        type:CREATE_MATERIAL,
        material
    }
}

export const Create_Material = (data,userId)=>{
    return dispatch=>{
        return createMaterial(data,userId).then((res)=>{
           if(res.data.case){
            dispatch(create_Material(res.data.material))
           }
           return res
        })
    }
}

// update material

export const UPDATE_MATERIAL = "UPDATE_MATERIAL";
const update_Material = (material)=>{
    return{
        type:UPDATE_MATERIAL,
        material
    }
}

export const Update_Material = (material)=>{
    return dispatch=> updateMaterial(material).then((res)=>{
        if(res.data.case){
            dispatch(update_Material(res.data.material))
        }
        return res
    })
}

//  delete material
export const DELETE_MATERIAL = "DELETE_MATERIAL";
const delete_Material = (id)=>{
    return {
        type:DELETE_MATERIAL,
        id
    }
}
export const Delete_Material = (id)=>{
    return dispatch=>deleteMaterial(id).then(res=>{
        if(res.data.case){
            dispatch(delete_Material(id))
        }
    })
}

// GET MATERIALS

export const GET_MATERIALS = 'GET_MATERIALS';
const get_Materials = (materials)=>{
    return{
        type:GET_MATERIALS,
        materials
    }
}

export const Get_Materials = ()=>dispatch=>getMaterials().then(res=>{
    if(res.data.case){
        dispatch(get_Materials(res.data.materials))
    }
    return res
})
