import { combineReducers } from "redux";
import { loggedUser } from "./login";
import { test, testResults } from './test'
import { users, admins } from "./register";
import { products } from "./products"
import { booking, appointments } from "./booking";
import { schedual } from "./schedual";
import { payment } from './payment';
import { meeting } from './etcMeeting';
import { translation } from './translation'
import { branchs, levels } from './levels'
import { materials } from './materials';
import { packages } from './packages';
import { view } from './view'
import { buyRequests } from "./buyRequests";
import { cart } from './cart';
import { store } from './store';
import { userMessages } from "./userMessages";
import { classes } from './class';
import { contacts } from "./massenger";
import { notify } from './notification';
import { language } from "./language";
import { posts } from './community';
import { packagessubscriptions } from "./packagesubscriptions";
import { zoomReducer } from "./zoomReducer";
import { mainMaterialRed } from "./mainMaterialRed";
import { eduRed } from "./eduRed";
import { mediaRed } from "./mediaRed";
import { transactionRed } from "./transactionRed";

export default combineReducers({
  loggedUser,
  notify,
  users,
  classes,
  admins,
  appointments,
  products,
  schedual,
  booking,
  meeting,
  translation,
  payment,
  test,
  testResults,
  branchs,
  levels,
  materials,
  view,
  packages,
  buyRequests,
  store,
  cart,
  userMessages,
  contacts,
  language,
  posts,
  packagessubscriptions,
  zoomReducer,
  mainMaterialRed,
  eduRed,
  mediaRed,
  transactionRed
});
