import React, { useState } from 'react'
import Timeline from './Timeline'
import PageContainer from '../layout/PagecContainer'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import "./timeline.css"
import SharedImage from './SharedImage'
import GetLevelName from '../shared/GetLevelName'

const Selectclassroom = () => {

    const params = useParams();

    console.log(params)
    const [selectedBranch, setSelectedBranch] = useState(null);

    const handleBranchClick = (level) => {
        setSelectedBranch(level);
    };

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(-1)
    }


    const location = useLocation();
    const levels = location.state?.levelData?.levelsIDS;
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className='container'>
                        <Timeline activeStep={3} />
                        <div className='row justify-content-between align-items-center' style={{ marginTop: "180px" }}>
                            <div className='col-lg-4 col-sm-12 col-md-12 '>
                                <SharedImage type={"room"} />
                            </div>
                            <div className='col-lg-8 col-sm-12 col-md-12'>
                                <div className='row justify-content-center align-items-center font mb-5'>
                                    {
                                        levels?.map((level, index) => (
                                            <div className='col-lg-3' key={level}>
                                                <div className={`text-center branchDiv ${selectedBranch === level ? 'active' : ''}`}
                                                    onClick={() => handleBranchClick(level)} >
                                                    <p >
                                                        <GetLevelName levelId={level} />
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {/* <div className='col-lg-3'>
                                            <div className={`text-center branchDiv ${selectedBranch === 3 ? 'active' : ''}`}
                                                onClick={() => handleBranchClick(3)} >
                                                <p >الصف الثالث الثانوي</p>
                                            </div>
                                        </div> */}

                                </div>
                            </div>
                        </div>
                        <div className='m-auto text-center mt-4 mb-5 font'>
                            <button onClick={handleNavigate} className='btn w-25 mx-2 prev'>السابق</button>


                            <Link to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${selectedBranch}`}

                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color: "white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >التالي</Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Selectclassroom
