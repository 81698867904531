import React from 'react'
import SliderService from './modules/SliderService'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';

const ServiceTeacher = () => {
    const params = useParams();

    const packageSubs = useSelector((state) => state.packagessubscriptions);


    const filteredPackageSubs = packageSubs?.filter(sub => sub.packageType === params?.type);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div className='font' dir={dir}>
            <div className='container' dir={dir}>
                {
                    params.type === "STORAGE" ? (
                        <div dir={dir}>
                            <h3 style={{ color: "#2B6CCD" }}>{HomePage.storagesPackage[lang]}</h3>
                            <p style={{ color: "black" }}>
                                {HomePage.infoStoragePack[lang]}
                            </p>
                        </div>
                    ) : (
                        <div dir={dir}>
                            <h3 style={{ color: "#2B6CCD" }}>{HomePage.packagesStd[lang]}</h3>
                            <p style={{ color: "black" }}>
                                {HomePage.infoStoragePack[lang]}
                            </p>
                        </div>
                    )
                }
                <div className='mt-5'>
                    <SliderService packageSubs={filteredPackageSubs} />
                </div>
            </div>
        </div>
    )
}

export default ServiceTeacher
