import { CREATE_EDUCTION_TYPE, DELETE_EDU_TYPE, GET_EDUCTION_TYPE, UPDATE_EDU_TYPE } from "../actions/eduAction";

export const eduRed = (state = [], action) => {
    switch (action.type) {

        case GET_EDUCTION_TYPE:
            return state = action.eduTypes;
        case CREATE_EDUCTION_TYPE:
            return state = [...state, action.eduType]
        case DELETE_EDU_TYPE:
            return state = state.filter(s => s._id !== action.id)
        case UPDATE_EDU_TYPE:
            return state = state.map(s => {
                if (s._id === action.eduType._id) {
                    return action.eduType
                }
                return s
            })
        default: return state;
    }
}