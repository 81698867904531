import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { getNewTeachers } from '../../utils/API';
import { ErrorMsg } from '../../components/shared/modules/Notifications';

const TableNewTeachers = () => {
    const teachers = useSelector(state => state.users.teachers);
    const loggedUser = useSelector((state) => state.loggedUser)
    const handleNewTeachers = async () => {
        try {
            const res = await getNewTeachers(loggedUser?.user?._id)
            console.log(res)
        } catch (e) {
            ErrorMsg(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        handleNewTeachers();
    }, [])

    console.log(teachers);
    return (
        <div className="scroll-overflow" style={{ width: "100%" }}>
            <Table responsive striped bordered hover className="font" style={{ fontSize: "20px" }}>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>الاسم</th>
                        <th>البريد الالكترونى</th>
                        <th>تاريخ التسجيل</th>
                        <th>التواصل</th>
                        <th>حذف</th>
                    </tr>
                </thead>
                <tbody style={{ fontSize: "20px" }}>
                    {teachers.map((t, i) => (
                        <tr style={{ fontSize: "20px" }}>

                            <td style={{ fontSize: "18px" }}>{t.name}</td>
                            <td style={{ fontSize: "18px" }}>{t.email}</td>
                            <td style={{ fontSize: "18px" }}>{t.date.split('T')[0]}</td>
                            <td style={{ fontSize: "18px" }}>
                                <a href={`https://wa.me/${t?.phone}`}>

                                </a>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}


export default TableNewTeachers


