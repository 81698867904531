import React, { useEffect, useState } from 'react'
import visa from "../../assets/visa.png"
import card from "../../assets/masterCard.png"
import fawry from "../../assets/fawry.png"
import { HomePage } from '../../utils/Translation/HomePage'
import { useSelector } from 'react-redux'
import PageContainer from '../layout/PagecContainer'
import Timeline from './Timeline'
import { Button } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { GetMaterialsByIds } from '../../utils/materials'
import { checkoutPay } from '../../utils/pay'
import { ErrorMsg } from '../shared/modules/Notifications'

const Pay = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    const location = useLocation();
    const classData = location.state?.classData;
    const [materials, setMaterials] = useState([])

    useEffect(() => {
        if (classData?.packID?.materials) {
            fetchMaterials()
        }
    }, [classData])

    const fetchMaterials = async () => {
        try {
            const materialCodes = classData?.packID?.materials?.map(mat => mat.materialCode)
            const res = await GetMaterialsByIds({ codes: materialCodes });
            // console.log("RESSS", res)
            if (res?.data?.case) {
                setMaterials(res.data.materials)
            }
        } catch (err) {
            console.log(err)
        }
    }




    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(-1)
    }


    const params = useParams();
    console.log(params)

    const loggedUser = useSelector((state) => state.loggedUser);


    const handleCheckOut = async () => {
        try {
            const res = await checkoutPay({
                packageId: classData?.packID?._id,
                classId: params?.idteacher,
            }, loggedUser?.user?._id)

            console.log(res)
            // if(res?.data?.case){
                window.location.href = `${res?.data?.hppUrl}`
            // }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        }
    }

    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div>
                        <Timeline activeStep={6} />
                    </div>
                    <div className='container'>
                        <div className='row justify-content-between align-items-start font mt-5 mb-5' dir={dir}
                            style={{ boxShadow: "0px 2px 2.1px 0px #00000040", padding: "15px", borderRadius: "10px" }}
                        >
                            <div className='col-lg-4 col-md-6 col-sm-12 col-12 '>
                                <div>
                                    <h3>بوابة الدفع</h3>
                                    <img src={fawry} alt="ImagedA" className='imagePayMent w-25' />
                                    <img src={visa} alt="ImageDA" className='imagePayMent w-25' />
                                    <img src={card} alt="ImageDA" className='imagePayMent w-25' />
                                </div>
                                <div clasName="mt-5">
                                    <div>
                                        <p><span style={{ fontWeight: "800", }}>اسم الفصل</span>:{classData?.name}</p>
                                        <p><span style={{ fontWeight: "800", }}>اسم الباقه</span>:{classData?.packID?.name}</p>
                                        <p>
                                            <span style={{ fontWeight: "800" }}>المواد</span>:
                                            {materials.map((mat, index) => (
                                                <span key={mat._id}>
                                                    {mat.name}{index < materials.length - 1 ? ' ، ' : ''}
                                                </span>
                                            ))}
                                        </p>
                                        {/* <p><span style={{ fontWeight: "800", }}>النظام</span>:البريطاني</p>
                                        <p><span style={{ fontWeight: "800", }}>الصف</span>:grade 10</p>

                                        <p><span style={{ fontWeight: "800", }}>التاريخ</span>:10-13-2024</p> */}

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-6 col-sm-12 col-12'>
                                <p>الحساب النهائي</p>
                                <div>
                                    {classData?.packID?.price && (
                                        <h5 className="mt-5">
                                            <span style={{ fontWeight: "800" }}>سعر الكورس قبل الخصم </span>:
                                            {classData?.packID?.price} {HomePage.egp[lang]}
                                        </h5>
                                    )}

                                    {classData?.packID?.discountPrice && (
                                        <h5 className="mt-5">
                                            <span style={{ fontWeight: "800" }}>سعر الكورس بعد الخصم </span>:
                                            {classData?.packID?.discountPrice} {HomePage.egp[lang]}
                                        </h5>
                                    )}
                                </div>
                                <hr />
                                <div>
                                    <h5>السعر النهائي: {classData?.packID?.discountPrice || classData?.packID?.price}</h5>
                                </div>

                                <div className='mt-5'>
                                    <Button variant="danger" onClick={handleNavigate}>
                                        الغاء
                                    </Button>
                                    <Link
                                        onClick={handleCheckOut}
                                        className="btn mx-3" style={{ background: "#071D5B", color: "white", textDecoration: "none" }} >
                                        ادفع
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Pay
