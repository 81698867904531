// import { GET_ALL_PACKAGES, CREATE_NEW_PACKAGE, UPDATE_PACKAGE, DELETE_PACKAGE } from "../actions/packages";

import { ADD_MAIN_MATERIAL_TO_BRANCH, DELETE_MAIN_MATERIAL_FROM_BRANCH, GET_MAIN_MATERIAL_FROM_LEVELID, GET_MATERIALS_LEVEL, UPDATE_MAIN_MATERIAL_FROM_BRANCH } from "../actions/mainMaterial"

export const mainMaterialRed = (state = [], action) => {
    switch (action.type) {
        case ADD_MAIN_MATERIAL_TO_BRANCH:
            return [...state, action.material]
        case UPDATE_MAIN_MATERIAL_FROM_BRANCH:
            return state.map(material =>
                material._id === action.id
                    ? action.material
                    : material
            );

        case GET_MAIN_MATERIAL_FROM_LEVELID:

            return state = action.materials
        case GET_MATERIALS_LEVEL:

            return state.filter(material => material.levelId === action.level)
        case DELETE_MAIN_MATERIAL_FROM_BRANCH:
            console.log("Action Main Material: ", action)
            console.log("state Main Material: ", state)
            return state.filter(material => material._id !== action.id);
        default: return state
    }
}

