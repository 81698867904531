import React, { useEffect, useState } from 'react'
import { getLessonById } from '../../../../utils/lessons';

const GetLessonById = (lesson) => {
    console.log(lesson)
    const [lessonName,setLessonName] = useState(null)
    const handleGetLesson = async()=>{
        try{
            const res = await getLessonById({_id:lesson?.lesson});
            console.log(res)
            if(res?.data?.case){
                setLessonName(res?.data?.lesson?.name)
            }
        }catch(e){
            console.log(e);
        }finally{

        }
    }

    useEffect(()=>{
        handleGetLesson();
    },[lesson])

    return (
        <div>
            <span>{lessonName}</span>
        </div>
    )
}

export default GetLessonById
