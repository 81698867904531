import React, { useEffect } from 'react'
import Addeductiontype from './Addeductiontype'
import { useDispatch, useSelector } from 'react-redux'
import { Get_Eduction_Type } from '../../../actions/eduAction';
import { Table } from 'react-bootstrap';
import UpdateEdu from './UpdateEdu';
import DeleteEdu from './DeleteEdu';

const EductionType = () => {
    const dispatch = useDispatch();

    const handleGetEduTypes = async () => {
        try {
            const res = await dispatch(Get_Eduction_Type());
            console.log(res)
        } catch (e) {
            console.log(e)
        } finally {

        }
    }

    useEffect(() => {
        handleGetEduTypes();
    }, [])
    const eduTypes = useSelector((state) => state.eduRed);

    console.log(eduTypes)
    return (
        <div>
            <Addeductiontype />

            <div>
                <Table responsive className='font mt-5'>
                    <thead>
                        <tr>
                            <th>الاسم</th>
                            <th>الفئات</th>
                            <th>التعديل</th>
                            <th>الحذف</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            eduTypes?.map((edu) => {
                                return (
                                    <tr key={edu?._id}>
                                        <td>{edu.name}</td>
                                        <td>
                                            {edu?.branchs?.length > 0 ? (
                                                edu.branchs.map((branch) => (
                                                    <p key={branch?._id}>{branch.name}</p>
                                                ))
                                            ) : (
                                                <p>لا توجد فئات</p>
                                            )}
                                        </td>
                                        <td>
                                            <UpdateEdu edu={edu} />
                                        </td>
                                        <td>
                                            <DeleteEdu edu={edu} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default EductionType
