import React from 'react'
import { Outlet } from 'react-router-dom';
import PageContainer from '../../components/layout/PagecContainer';

const ReservationSystemWrapper = () => {
  return (
    <div>

      <Outlet />

    </div>
  )
}

export default ReservationSystemWrapper
